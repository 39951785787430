import React from "react";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import MainSection from "../../../shared/components/mainSection";
import styles from "./about.module.scss";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import BottomSection from "../../../shared/components/bottomSection";

const About: React.FC = () => {
  const { t } = useTranslation();
  const auImage = useResponsiveImage(ImageKeys.australia);

  const brandValues = [
    {
      title: "about.insightful",
      value: "about.insightfulDescription",
    },
    {
      title: "about.empathic",
      value: "about.empathicDescription",
    },
    {
      title: "about.dexterous",
      value: "about.dexterousDescription",
    },
    {
      title: "about.optimistic",
      value: "about.optimisticDescription",
    },
  ];
  return (
    <div className={`gradient-background`}>
      {generateMeta({
        title: metaData.about.title,
        description: metaData.about.description,
      })}
      <div className={styles["about-container"]}>
        <MainSection
          label={"about.title"}
          bigTitle
          containerClass={styles["container"]}
          labelClass={styles["mainSection-title"]}
          descriptionClass={styles["mainSection-description"]}
          transparentBackground
          showConsultationButton={false}
          description={"about.description"}
          smallLabelStyle
          customClassName={styles["custom-container"]}
        />
        <div
          className={`${styles["about-content"]} main-content d-flex justify-content-center`}
        >
          <div className={"col-lg-6 col-md-12 col-sm-12 p-0"}>
            <p>{t("about.EmpoweringEvolution")}</p>
            <div className={styles["au-image"]}>
              <LazyLoadImage
                src={auImage}
                width={"100%"}
                height={"auto"}
                alt={"australia"}
              />
            </div>
            <h2>{t("about.ourBrandValues")}</h2>
            {brandValues.map((item) => (
              <div key={item.title}>
                <h3>{t(item.title)}</h3>
                <p className={"m-0"}>{t(item.value)}</p>
              </div>
            ))}
            <h2 className={styles["mission-title"]}>{t("about.mission")}</h2>
            <p>{t("about.missionDescription")}</p>
            <h2 className={styles["vision-title"]}>{t("about.vision")}</h2>
            <p>{t("about.visionDescription")}</p>
          </div>
        </div>
        <BottomSection />
      </div>
    </div>
  );
};

export default About;
