import axios, { InternalAxiosRequestConfig } from "axios";

/**
 * Axios instance with default configuration.
 * @type {import('axios').AxiosInstance}
 */
const axiosInstance = axios.create({
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Accept: "application/json",
    Expires: "0",
  },
});

/**
 * Axios interceptor for the request to add the authentication token.
 * @param {InternalAxiosRequestConfig<any>} config - The Axios request config.
 * @returns {Promise<InternalAxiosRequestConfig<any>>} The modified Axios request config with authentication token.
 */
axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const tempConfig = { ...config };
    tempConfig.timeout = 30000;
    tempConfig.timeoutErrorMessage = "timeout";
    return tempConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);
/**
 * Axios interceptor for the response to clear the HTTP cache.
 * @param {import('axios').AxiosResponse} response - The Axios response.
 * @returns {Promise<import('axios').AxiosResponse>} The original Axios response after clearing HTTP cache.
 */
axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
