import React, { useCallback } from "react";
import { MultiCoverProps } from "../../../../../interfaces/screens/caseStudyDetails";
import ResponsiveMockup from "../../../../../components/responsiveMockup";
import detailsMockupConfigs from "../../../../../shared/static/detailsMockupConfig";
import { getCaseStudyTypeById } from "../../../../../shared/utils/helper";
import { CategoryTypes } from "../../../../../interfaces/components/caseStudyItem";
import styles from "./multiCover.module.scss";
import { CategoryIds } from "../../../../../shared/utils/enums";
import { Category } from "../../../../../interfaces/api/caseStudy";

const MultiCover: React.FC<MultiCoverProps> = ({ images, headerId }) => {
  const getConfigByCategory = (categoryId: number) => {
    const type: CategoryTypes = getCaseStudyTypeById(categoryId);
    return detailsMockupConfigs[type];
  };

  const getContainerClass = useCallback(() => {
    const list = images.map((item) => +item.category_id);
    const brandingAndMobile =
      images.length === 2 &&
      list.includes(CategoryIds.mobile) &&
      list.includes(CategoryIds.branding);
    return brandingAndMobile ? styles["mobile-branding-container"] : "";
  }, [images]);

  const getClassById = useCallback(
    (id: number) => {
      switch (id) {
        case CategoryIds.mobile:
          return styles["mobile-item"];
        case headerId:
          return styles["main-item"];
        case CategoryIds.software:
          return `${styles["mac-item"]} ${styles["small-item"]}`;
        case CategoryIds.branding:
          return `${styles["branding-item"]} ${styles["small-item"]}`;
        default:
          return styles["small-item"];
      }
    },
    [headerId],
  );

  // Filter to get the main item and the rest of the items
  const mainItem = images.find((item) => +item.category_id === headerId);
  const otherItems = images.filter((item) => +item.category_id !== headerId);

  // Check if there is an item with category_id 1 in the list
  const hasMobileItem = images.some(
    (item) => +item.category_id === CategoryIds.mobile,
  );
  const mobileItem = images.find(
    (item) => +item.category_id === CategoryIds.mobile,
  ) as Category;

  // Determine items to display
  let itemsToDisplay: Category[] = [];
  if (hasMobileItem) {
    if (mainItem) {
      const randomOtherItem = otherItems.filter(
        (item) => +item.category_id !== CategoryIds.mobile,
      )[0] as Category;
      itemsToDisplay = [mainItem, randomOtherItem, mobileItem];
    } else {
      itemsToDisplay = otherItems.slice(0, 3);
    }
  } else {
    itemsToDisplay = mainItem
      ? [mainItem, ...otherItems.slice(0, 1)]
      : otherItems.slice(0, 2);
  }

  return (
    <div
      className={`d-flex align-items-center relative ${getContainerClass()}`}
    >
      {itemsToDisplay.map((item) => (
        <div key={item.id} className={getClassById(+item.category_id)}>
          <ResponsiveMockup
            imageUrl={item.cover.url.desktop}
            {...(getConfigByCategory(+item.category_id) as any)}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiCover;
