import React, { useRef } from "react";
import "./carousel.scss";
import { CarouselProps } from "../../../interfaces/shared/components/carousel";
import Slider from "@ant-design/react-slick";
import nextArrow from "../../../assets/images/shared/icons/nextIcon.svg";
import prevArrow from "../../../assets/images/shared/icons/prevIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CustomCarousel: React.FC<CarouselProps<any>> = ({
  list,
  renderItem,
  className = "",
  loop = false,
  blueDots = false,
  slidesToShow = 1,
  arrows = false,
  showDots = true,
  onSwipe,
  centerMode = false,
  slidesToScroll = 1,
}) => {
  const clickableRef = useRef(true);
  const sliderRef = useRef<Slider>(null);

  const handleClick = (event: MouseEvent) => {
    if (!clickableRef.current) {
      event.stopPropagation();
      event.preventDefault();
    }
    clickableRef.current = true;
  };
  const swipeEvent = () => {
    if (sliderRef?.current?.innerSlider?.list) {
      sliderRef.current.innerSlider.list.onclick = handleClick;
      clickableRef.current = false;
    }
  };

  return (
    <div
      className={`${blueDots ? "blue-dots-container" : "white-dots-container"} slider-container ${className}`}
    >
      <Slider
        slidesToShow={slidesToShow}
        variableWidth
        centerMode={centerMode || slidesToShow === 1}
        infinite={loop}
        slidesToScroll={slidesToScroll}
        arrows={arrows}
        dots={showDots}
        onSwipe={onSwipe}
        ref={sliderRef}
        nextArrow={
          <LazyLoadImage
            src={nextArrow}
            width={45}
            height={45}
            alt={"next arrow"}
          />
        }
        prevArrow={
          <LazyLoadImage
            src={prevArrow}
            width={45}
            height={45}
            alt={"next arrow"}
          />
        }
        swipeEvent={swipeEvent}
      >
        {list.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem({ item, index })}
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
};

export default CustomCarousel;
