import React from "react";
import styles from "./weDesignForHuman.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WeDesignBackground from "../../../../../assets/images/shared/userExperienceDesign/weDesignBackground.webp";
import { useTranslation } from "react-i18next";
const WeDesignForHuman = () => {
  const { t } = useTranslation();

  return (
    <section
      id={"we-design-for-human"}
      className={`${styles["weDesignForHuman-container"]} relative`}
    >
      <div className={"relative overflow-hidden"}>
        <LazyLoadImage
          src={WeDesignBackground}
          alt={"we design"}
          width={"100%"}
          height={"100%"}
          className={styles["weDesign-border"]}
        />
        <div className={styles["page-content"]}>
          <h2>
            <span className={styles["blue-text"]}>
              {t("userExperienceDesign.weDesign")}
            </span>
            {"\n"}
            <span>{t("userExperienceDesign.forHuman")}</span>
          </h2>
          <p className={styles["description"]}>
            {t("userExperienceDesign.weDesignDescription")}
          </p>
          <p className={styles["questions"]}>
            {t("userExperienceDesign.weDesignQuestions")}
          </p>
          <p className={styles["note"]}>
            {t("userExperienceDesign.weDesignNote")}
            <span style={{ fontWeight: "bold" }}>
              {t("userExperienceDesign.inspireTheFuture")}
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default WeDesignForHuman;
