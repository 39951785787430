import http from "../http";
import api from "../../config/api";
import { MainResponse } from "../../interfaces/api/response";

const contact = {
  submit: (form: FormData): Promise<MainResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.contact, form)
        .then((res) => resolve(res as MainResponse))
        .catch((e) => reject(e));
    }),
};

export default contact;
