import { useEffect, RefObject } from "react";
import { isBrowserDefined } from "../services/browserService";

/**
 * Specify the type for ref as RefObject<T> where T is the type of the HTML element you are referencing.
 * The callback function doesn't take any parameters and doesn't return anything, so its type is () => void.
 */
const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
): void => {
  useEffect(() => {
    if (isBrowserDefined()) {
      /**
       * The event parameter is of type MouseEvent.
       */
      const handleClickOutside = (event: MouseEvent): void => {
        // Check if the click target is not inside the ref element.
        // The event.target is of type EventTarget, so you need to assert its type as Node to use it with contains.
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };

      // Bind the event listener.
      document.addEventListener("mousedown", handleClickOutside);

      return (): void => {
        // Unbind the event listener on clean up.
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [ref, callback]);
};

export default useClickOutside;
