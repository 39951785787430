import { MetaData } from "../interfaces/config/metaData";
const description =
  "Direct and develop your mobile application, software or website project with the help of the most innovative and nimble talents on the market.";
const metaData: MetaData = {
  home: {
    title:
      "CanGuru  |  Emerging Technologies, Mobile Applications, Software, Web Design & Development",
    description,
  },
  about: {
    title:
      "CanGuru | About US, Business Culture, Business Values, Work Philosophy.",
    description,
  },
  caseStudy: {
    title: "CanGuru | Case Study.",
    description,
  },
  insights: {
    title: "CanGuru | Insights, Blogs.",
    description,
  },
  mobileApps: {
    title: "CanGuru | Mobile Apps, iOS Apps, Android Apps, Cross-platform.",
    description,
  },
  webSolutions: {
    title: "CanGuru | Web Applications, Responsive Websites, Dynamic Websites.",
    description,
  },
  animation: {
    title:
      "CanGuru | Animation, 2D Animation, 3D Animation, Motion Graphics, CGI.",
    description,
  },
  industrySpecificSoftware: {
    title: "CanGuru |  Industrial Solutions, Solutions for your industry.",
    description,
  },
  userExperienceDesign: {
    title:
      "CanGuru |  User Experience Design, Elevating User Experience with Design, Interaction, Research, and Innovation.",
    description,
  },
  branding: {
    title:
      "CanGuru | Design, Branding, Corporate Identity, Logo Design, UI, UX, IxD, Brand Activation.",
    description,
  },
  digitalMarketing: {
    title:
      "CanGuru | Digital Marketing, SEO, SEM, Social Media, Inbound Marketing, Content Creation.",
    description,
  },
  contactUS: {
    title: "CanGuru | Contact Us",
    description,
  },
  notFound: {
    title: "CanGuru | Not Found.",
    description,
  },
};

export default metaData;
