import { useMemo } from "react";
import useDeviceType from "./deviceType";
import { images } from "../../assets/images";

const useResponsiveImage = (imageName: keyof typeof images): string => {
  const deviceType = useDeviceType();

  return useMemo((): string => {
    // Define the base path for images
    const mainImage = images[imageName];
    return mainImage[deviceType] || mainImage["desktop"];
  }, [deviceType, imageName]);
};

export default useResponsiveImage;
