import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./conversionNavigator.module.scss";
import { letsTalk } from "../../../../../shared/services/contactService";

const ConversionNavigator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section
      id={"conversion-navigator"}
      className={styles["conversionNavigator-container"]}
    >
      <p className={styles["description"]}>
        {t("digitalMarketing.conversionNavigatorDescription")}
      </p>
      <div className={"col-12 d-flex justify-content-center"}>
        <p className={`letsTalk ${styles["custom-lets-talk"]}`}>
          {t("digitalMarketing.needABoost")}{" "}
          <a href={"#contact"} onClick={(event) => letsTalk(event)}>
            {t("app.letsTalk")}
          </a>
        </p>
      </div>
    </section>
  );
};

export default ConversionNavigator;
