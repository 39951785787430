import { FooterMenu, SocialMenu } from "../interfaces/config/footer";
import socialMediaItems from "./socialMedia";

const footerMenu: FooterMenu = {
  column1: [
    {
      label: "menu.mobileApps",
      path: "services/mobile-app",
    },
    {
      label: "footer.software",
      path: "services/industry-specific-software",
    },
    {
      label: "menu.webSolutions",
      path: "services/web-solutions",
    },
    {
      label: "footer.uxDesign",
      path: "services/user-experience-design",
    },
  ],
  column2: [
    {
      label: "menu.branding",
      path: "services/branding",
    },
    {
      label: "menu.digitalMarketing",
      path: "services/digital-marketing",
    },
    {
      label: "menu.animation",
      path: "services/animation",
    },
  ],
  column3: [
    {
      label: "menu.insights",
      path: "insights",
    },
    {
      label: "menu.about",
      path: "about",
    },
  ],
};

const socialMedia: SocialMenu = {
  column1: [
    socialMediaItems.linkedin,
    socialMediaItems.instagram,
    socialMediaItems.facebook,
  ],
  column2: [],
};

const tabletSocialMedia: SocialMenu = {
  column1: [socialMediaItems.facebook],
  column2: [socialMediaItems.linkedin],
  column3: [socialMediaItems.instagram],
};

const mobileSocialMedia = [
  socialMediaItems.linkedin,
  socialMediaItems.instagram,
  socialMediaItems.facebook,
];
export const footer = {
  menu: footerMenu,
  socialMedia,
  tabletSocialMedia,
  mobileSocialMedia,
};
