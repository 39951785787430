import React from "react";
import { useTranslation } from "react-i18next";
import industriesWeServeList from "../../../../../shared/static/industriesWeServe";
import styles from "./industriesWeServe.module.scss";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import { letsTalk } from "../../../../../shared/services/contactService";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";

const IndustriesWeServe = () => {
  const { t } = useTranslation();
  const background = useResponsiveImage(ImageKeys.industrySoftwareBackground);
  return (
    <section
      id={"industries-we-serve"}
      className={`${styles["industriesWeServe-container"]} relative`}
    >
      <LazyLoadImage
        className={"background-image"}
        src={background}
        placeholderSrc={getThumbnail(ImageKeys.industrySoftwareBackground)}
        alt={"we can do"}
      />
      <div className={`relative ${styles["text-content"]}`}>
        <h2 className={"relative"}>
          {t("industrialSoftware.industriesWeServe")}
        </h2>
        <div className={"row"}>
          {industriesWeServeList.map((item, idx) => (
            <div
              className={`col-lg-6 col-md-12 col-sm-12 ${styles["industry-item"]} ${styles[idx % 2 === 0 ? "left" : "right"]}`}
              key={idx}
            >
              <h3>{t(item.title)}</h3>
              <p>{t(item.description)}</p>
            </div>
          ))}
        </div>
        <div className={"col-12 d-flex justify-content-center"}>
          <p className={`letsTalk ${styles["custom-letsTalk"]}`}>
            {t("industrialSoftware.lookingForSolution")}{" "}
            <a href={"#contact"} onClick={(event) => letsTalk(event)}>
              {t("app.letsTalk")}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default IndustriesWeServe;
