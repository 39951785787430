import { useState, useEffect } from "react";
import { getBlogDetails } from "./actions";
import { Blog, BlogHookResponse } from "../../../interfaces/api/blogs";

const useBlogDetails = (slug: string): BlogHookResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blog, setBlog] = useState<Blog>({} as Blog);
  const [similarBlogs, setSimilarBlogs] = useState<Blog[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);
        const res = await getBlogDetails(slug);
        if (res) {
          setBlog(res.article);
          setSimilarBlogs(res.similarArticles.data);
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [slug]);

  return { isLoading, blog, similarBlogs, isError };
};

export default useBlogDetails;
