import React from "react";
import styles from "./brandingItem.module.scss";
import { BrandingItemProps } from "../../interfaces/components/brandingItem";
const BrandingItem: React.FC<BrandingItemProps> = ({
  label,
  containerClass,
}) => {
  return (
    <div
      className={`${styles["brandingItem-container"]} d-flex align-items-center justify-content-center ${containerClass}`}
    >
      <p>{label}</p>
    </div>
  );
};

export default BrandingItem;
