import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./contact.module.scss";
import Button from "../button";
import Checkbox from "../checkbox";
import { ContactForm, ContactProps } from "../../interfaces/components/contact";
import { sendContact } from "./actions";
import { toastService } from "../../shared/utils/toast";
import { handleChange, validateEmail } from "../../shared/utils/formHelper";

/**
 * Array containing mandatory fields of the contact form.
 * @type {(keyof ContactForm)[]}
 */
const mandatoryFields: (keyof ContactForm)[] = [
  "name",
  "organization",
  "email",
  "brief_us",
];
const Contact: React.FC<ContactProps> = ({ withTitle = true, lightForm }) => {
  const { t } = useTranslation();
  /**
   * State to track if the form has been submitted.
   */
  const [submitted, setSubmitted] = useState<boolean>(false);
  /**
   * State to manage the form data.
   */
  const [form, setForm] = useState<ContactForm>({
    name: "",
    email: "",
    organization: "",
    request_nda: false,
    brief_us: "",
  });
  /**
   * Handles form submission.
   */
  const handleSubmit = async () => {
    setSubmitted(true);
    const missingFields = mandatoryFields.filter(
      (field: keyof ContactForm) => !form[field],
    );
    if (missingFields.length === 0) {
      if (!validateEmail(form.email)) {
        toastService.showErrorToast("Please enter a valid email address!");
        return;
      }
      const temp = { ...form };
      temp["request_nda"] = temp["request_nda"] ? "1" : "0";
      const res = await sendContact(temp);
      if (!res) return;
      setForm({
        name: "",
        email: "",
        organization: "",
        request_nda: false,
        brief_us: "",
      });
      toastService.showSuccessToast(t("contact.submitted"));
      setSubmitted(false);
    } else {
      toastService.showErrorToast(t("app.requiredFieldsError"));
    }
  };
  return (
    <section
      id={"contact"}
      className={`${styles["contact-container"]} ${!withTitle ? styles["withoutTitle-container"] : ""}`}
    >
      {withTitle && (
        <h2>
          <span className={styles["part1"]}>{t("contact.title1")}</span>
          <br />
          <span className={styles["part2"]}>{t("contact.title2")}</span>
        </h2>
      )}
      <div
        className={`${styles["form-container"]} row ${lightForm ? styles["light-theme"] : ""}`}
      >
        <div className={"col-lg-6 col-md-12 col-sm-12"}>
          <input
            placeholder={t("contact.name")}
            value={form["name"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("name", e.target.value, form, setForm)
            }
            aria-label={t("contact.name")}
            className={submitted && !form.name ? styles["invalid-input"] : ""}
          />
        </div>
        <div className={"col-lg-6 col-md-12 col-sm-12"}>
          <input
            placeholder={t("contact.email")}
            value={form["email"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("email", e.target.value, form, setForm)
            }
            aria-label={t("contact.email")}
            className={submitted && !form.email ? styles["invalid-input"] : ""}
          />
        </div>
        <div className={"col-lg-6 col-md-12 col-sm-12"}>
          <input
            placeholder={t("contact.organisation")}
            value={form["organization"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("organization", e.target.value, form, setForm)
            }
            aria-label={t("contact.organisation")}
            className={
              submitted && !form.organization ? styles["invalid-input"] : ""
            }
          />
        </div>
        <div className={"col-lg-6 col-md-12 col-sm-12"}>
          <Checkbox
            label={t("contact.requestNDA")}
            value={!!form["request_nda"]}
            lightMode={lightForm}
            onValueChange={(val: boolean) =>
              handleChange("request_nda", val, form, setForm)
            }
          />
        </div>
        <div className={"col-lg-12 col-md-12 col-sm-12"}>
          <textarea
            rows={12}
            placeholder={t("contact.brief")}
            value={form["brief_us"]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange("brief_us", e.target.value, form, setForm)
            }
            aria-label={t("contact.brief")}
            className={
              submitted && !form.brief_us ? styles["invalid-input"] : ""
            }
          ></textarea>
        </div>
        <div className={"col-12 d-flex justify-content-center"}>
          <Button
            className={styles["submit"]}
            label={t("app.freeConsultation")}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </section>
  );
};

export default Contact;
