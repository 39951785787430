import { images } from "../../assets/images";

export const getThumbnail = (
  imageName: keyof typeof images,
  alt = "main",
): string => {
  if (!images[imageName]?.thumbnail) {
    return "";
  }
  return images[imageName].thumbnail || "";
};
