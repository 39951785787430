import React from "react";
import { ButtonProps } from "../../interfaces/components/button";
import styles from "./button.module.scss";
const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = "button",
  style,
  className,
  buttonType = "light",
}) => {
  return (
    <button
      style={style}
      className={`${styles["main-button"]} ${buttonType === "dark" && styles["main-dark-button"]} ${className}`}
      type={type}
      onClick={onClick}
      aria-label={label}
    >
      {label}
    </button>
  );
};

export default Button;
