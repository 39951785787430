import React, { useRef, useEffect, useState, FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface LazyVideoProps {
  src: string;
  type?: string;
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
  className?: string;
  width?: string;
  height?: string;
}

const LazyVideo: FC<LazyVideoProps> = ({
  src,
  type = "video/webm",
  controls = false,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isInView, setIsInView] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              setIsInView(true);
              if (videoRef.current) observer.unobserve(videoRef.current);
            }, 400);
          }
        });
      },
      { threshold: 0.5 },
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.onloadeddata = () => {
          setVideoLoaded(true);
        };
      }
    }
  }, [isInView]);

  return (
    <React.Fragment>
      {!!props.poster && (
        <LazyLoadImage
          className={`${props.className} ${videoLoaded ? "hidden" : ""}`}
          src={props.poster}
          width={props.width || "auto"}
          height={props.height || "auto"}
          alt="background"
          style={{ position: "absolute" }}
        />
      )}
      <video
        ref={videoRef}
        controls={controls}
        {...props}
        className={`${props.className} ${!videoLoaded ? "fade-out" : ""}`}
      >
        {isInView && <source src={src} type={type} />}
        Your browser does not support the video tag.
      </video>
    </React.Fragment>
  );
};

export default LazyVideo;
