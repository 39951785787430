import React from "react";
import styles from "./customProcess.module.scss";
import OurProcessItem from "../ourProcessItem";
import { useTranslation } from "react-i18next";
import { CustomProcessProps } from "../../interfaces/components/customProcess";
import useIsDesktop from "../../shared/hooks/isDesktop";

const CustomProcess: React.FC<CustomProcessProps> = ({
  ourProcessList,
  leftTitle,
  rightTitle,
  borderBoxClass = "",
  className = "",
  processItemClass = "",
  processItemDescriptionClass = "",
  leftTitleClassName = "",
  rightTitleClassName = "",
  titleClassName = "",
  homeType = true,
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  return (
    <section
      className={`${styles["customProcess-container"]} ${className}`}
      id={"customProcess"}
    >
      <div className={`${styles["border-box"]} ${borderBoxClass}`}></div>
      {!isDesktop && (
        <h2 className={`${styles["mobile-title"]} ${titleClassName}`}>
          <span className={leftTitleClassName}>{t(leftTitle)}</span>{" "}
          <span className={rightTitleClassName}>{t(rightTitle)}</span>
        </h2>
      )}
      <div className={`${styles["row"]}`}>
        {ourProcessList.map((item) => (
          <div
            key={item.index}
            className={`col-lg-6 col-md-12 col-sm-12 ${styles["item"]}`}
          >
            {item.index === 1 && isDesktop && (
              <h2 className={styles["customProcessTitle-part1"]}>
                {t(leftTitle)}
              </h2>
            )}
            {item.index === 2 && isDesktop && (
              <h2 className={styles["customProcessTitle-part2"]}>
                {t(rightTitle)}
              </h2>
            )}
            <OurProcessItem
              title={t(item.title)}
              description={t(item.description)}
              index={item.index}
              className={processItemClass}
              descriptionClass={processItemDescriptionClass}
              homeType={homeType}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default CustomProcess;
