import React from "react";
import styles from "./blogContent.module.scss";
import InsightTag from "../../../../../components/insightTag";
import { BlogContentProps } from "../../../../../interfaces/screens/insightDetails/blogContent";
import { useTranslation } from "react-i18next";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";

const BlogContent: React.FC<BlogContentProps> = ({ blog }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  return (
    <div className={styles["blogContent-container"]}>
      <h1>{blog.title}</h1>
      <LazyLoadImage
        className={styles["cover"]}
        src={blog.image[isDesktop ? "desktop" : "mobile"]}
        alt={blog.title}
        placeholderSrc={blog.image.thumbnail}
        originalImage={blog.image.original}
      />
      <div
        className={styles["blog-content"]}
        dangerouslySetInnerHTML={{ __html: blog.content }}
      ></div>
      {!!blog.tags.length && <p className={"mt-2"}>{t("insights.keywords")}</p>}
      <div className={`${styles["tag-container"]} d-flex align-items-center`}>
        {blog.tags.map((item) => (
          <InsightTag key={item.id} label={item.name} />
        ))}
      </div>
    </div>
  );
};

export default BlogContent;
