import React from "react";
import styles from "./weDesignForBrands.module.scss";
import { useTranslation } from "react-i18next";
const WeDesignForBrands = () => {
  const { t } = useTranslation();
  return (
    <section
      className={`${styles["weDesignForBrands-container"]} relative`}
      id={"we-design-for-brands"}
    >
      <h2>
        <span className={styles["blue-text"]}>{t("branding.weDesign")}</span>
        {"\n"}
        <span>{t("branding.forBranding")}</span>
      </h2>
      <p>{t("branding.designDescription")}</p>
    </section>
  );
};

export default WeDesignForBrands;
