import React from "react";
import { SkeletonProps } from "../../interfaces/components/skeleton";
import styles from "./skeleton.module.scss";
const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  marginTop,
  marginBottom,
  borderRadius,
  blurLevel = 0,
}) => {
  return (
    <div
      className={styles["skeleton-container"]}
      style={{
        width: width || "100%",
        height: height || "100%",
        marginTop: marginTop || "1%",
        marginBottom: marginBottom || "1%",
        borderRadius: borderRadius || "12px",
        backdropFilter: `blur(${blurLevel}px)`,
      }}
    ></div>
  );
};

export default Skeleton;
