const developmentHubList: { title: string; description: string }[] = [
  {
    title: "mobileApps.nativeApplicationsTitle",
    description: "mobileApps.nativeApplicationDescription",
  },
  {
    title: "mobileApps.crossPlatformTitle",
    description: "mobileApps.crossPlatformDescription",
  },
  {
    title: "mobileApps.hybridApplicationsTitle",
    description: "mobileApps.hybridApplicationDescription",
  },
  {
    title: "mobileApps.decentralisedApplicationsTitle",
    description: "mobileApps.decentralisedApplicationsDescription",
  },
  {
    title: "mobileApps.emergingTechApplicationTitle",
    description: "mobileApps.emergingTechApplicationDescription",
  },
  {
    title: "mobileApps.customBuiltApplicationsTitle",
    description: "mobileApps.customBuiltApplicationsDescription",
  },
];

export default developmentHubList;
