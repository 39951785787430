import { useEffect, useState } from "react";
import { DeviceTypes } from "../utils/enums";
import { isBrowserDefined } from "../services/browserService";

const getDeviceType = (width: number): DeviceTypes => {
  if (width <= 743) {
    return DeviceTypes.mobile;
  } else if (width <= 1024) {
    return DeviceTypes.tablet;
  } else if (width <= 1440) {
    return DeviceTypes.desktop;
  }
  return DeviceTypes.wideDesktop;
};

const useDeviceType = (): DeviceTypes => {
  const [deviceType, setDeviceType] = useState<DeviceTypes>(
    // On the server, `window` is undefined, so default to a safe value.
    isBrowserDefined() ? getDeviceType(window.innerWidth) : DeviceTypes.desktop,
  );

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType(window.innerWidth));
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return deviceType;
};

export default useDeviceType;
