import http from "../http";
import api from "../../config/api";
import { BlogDetailsResponse, BlogsResponse } from "../../interfaces/api/blogs";

const insights = {
  getBlogs: (page: number): Promise<BlogsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(`${api.blogs}?page=${page}`)
        .then((res) => resolve(res as BlogsResponse))
        .catch((e) => reject(e));
    }),
  blogDetails: (slug: string): Promise<BlogDetailsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.blogDetails + slug)
        .then((res) => resolve(res as BlogDetailsResponse))
        .catch((e) => reject(e));
    }),
};

export default insights;
