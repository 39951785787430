import React from "react";
import ReactDOM from "react-dom/client";
import MainRouter from "./router";
import { BrowserRouter } from "react-router-dom";
import "./shared/styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <BrowserRouter>
    <MainRouter />
  </BrowserRouter>,
);

reportWebVitals();
