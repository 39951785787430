import React from "react";
import styles from "./categoryItem.module.scss";
import { CategoryItemProps } from "../../interfaces/components/categoryItem";

const CategoryItem: React.FC<CategoryItemProps> = ({
  label,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles["categoryItem-container"]} ${selected ? styles["selected"] : ""}`}
    >
      <p>{label}</p>
    </div>
  );
};

export default CategoryItem;
