import React from "react";
import Skeleton from "../../../../../components/skeleton";
import styles from "./insightDetailsLoader.module.scss";
const InsightDetailsLoader: React.FC = () => {
  return (
    <div className={styles["loader-container"]}>
      <Skeleton height={"22px"} />
      <Skeleton height={"22px"} width={"60%"} marginBottom={"100px"} />
      <Skeleton height={"300px"} borderRadius={"20px"} marginBottom={"30px"} />
      <Skeleton height={"800px"} />
    </div>
  );
};

export default InsightDetailsLoader;
