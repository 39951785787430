import { useState, useEffect } from "react";
import { isBrowserDefined } from "../services/browserService";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isBrowserDefined()) {
      const handleScroll = () => {
        setScrollPosition({
          x: window.pageXOffset,
          y: window.pageYOffset,
        });
      };
      window.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
