import { isBrowserDefined } from "../services/browserService";
import parse from "node-html-parser";
import { CategoryTypes } from "../../interfaces/components/caseStudyItem";

export const openLink = (link: string) => {
  if (!isBrowserDefined()) return;
  window.open(link, "_blank");
};

export const extractContent = (
  html: string,
  wordLimit = 50,
  tail = "... (Read more)",
  charLimit?: number,
): string => {
  const root = parse(html);
  const textContent = root.textContent || ""; // Extracts text content from the parsed HTML

  let excerpt = "";
  let currentLength = 0;

  if (charLimit !== undefined && charLimit < textContent.length) {
    excerpt = textContent.slice(0, charLimit);
  } else {
    const words = textContent.split(/\s+/);
    for (let i = 0; i < words.length && i < wordLimit; i++) {
      if (
        charLimit !== undefined &&
        currentLength + words[i].length + 1 > charLimit
      ) {
        excerpt = textContent.slice(0, currentLength).trim();
        break;
      }
      excerpt += words[i] + " ";
      currentLength += words[i].length + 1; // Update length with word and a space
    }
  }
  return excerpt.length < textContent.length
    ? `${excerpt.trim()}${tail}`
    : excerpt.trim();
};

export const getPages = (
  numOfPages: number,
  currentPage: number,
): { endVisiblePages: number[]; firstVisiblePages: number[] } => {
  let endVisiblePages: number[] = [];
  let firstVisiblePages: number[] = [];
  if (numOfPages < 13) {
    firstVisiblePages = Array.from({ length: numOfPages }, (_, i) => i + 1);
    return { endVisiblePages, firstVisiblePages };
  } else if (currentPage < 12) {
    firstVisiblePages = Array.from({ length: 13 }, (_, i) => i + 1);
    return { endVisiblePages, firstVisiblePages };
  }
  firstVisiblePages = [1];
  const backItems =
    numOfPages - 12 > currentPage
      ? currentPage - 1
      : numOfPages - (currentPage < numOfPages - 4 ? 13 : 11);
  endVisiblePages = Array.from({ length: 12 }, (_, i) => i + backItems);

  return { endVisiblePages, firstVisiblePages };
};

export const truncateText = (text: string, limit: number = 50) => {
  if (!text) return "";
  if (text.length <= limit) return text;
  return `${text.substring(0, limit)}...`;
};

export const getCaseStudyTypeById = (id: number): CategoryTypes => {
  if (id === 1) return "mobile";
  if (id === 2 || id === 6) return "web";
  if (id === 3) return "laptop";
  if (id === 7) return "animation";
  return "branding";
};
