import React from "react";
import styles from "./contactUs.module.scss";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import MainSection from "../../../shared/components/mainSection";
import { useTranslation } from "react-i18next";
import Contact from "../../../components/contact";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className={`gradient-background`}>
      {generateMeta({
        title: metaData.contactUS.title,
        description: metaData.contactUS.description,
      })}
      <div className={styles["contactUs-container"]}>
        <MainSection
          label={"contactUs.title"}
          bigTitle
          containerClass={styles["container"]}
          transparentBackground
          customClassName={styles["custom-container"]}
          showConsultationButton={false}
          description={"contactUs.description"}
          labelClass={styles["mainSection-title"]}
          descriptionClass={styles["description"]}
        />
        <Contact withTitle={false} lightForm />
      </div>
    </div>
  );
};

export default ContactUs;
