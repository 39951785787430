import React from "react";
import styles from "./thingsWeDo.module.scss";
import { useTranslation } from "react-i18next";
import BrandingItem from "../../../../../components/brandingItem";
const ThingsWeDo = () => {
  const { t } = useTranslation();
  const thingsList: string[] = [
    "brandAnalysisResearch",
    "brandVoiceStory",
    "visualIdentityGraphics",
    "brandActivationPublishing",
  ];

  return (
    <section
      id={"things-we-do"}
      className={`${styles["thingsWeDo-container"]} relative`}
    >
      <h2>{t("branding.thingsWeDo")}</h2>
      <div className={"row"}>
        {thingsList.map((item, idx) => (
          <div className={"col-lg-6 col-sm-12 col-sm-12"}>
            <BrandingItem key={idx} label={t(`branding.${item}`)} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ThingsWeDo;
