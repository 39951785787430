import React, { useEffect, useState } from "react";
import { PaginatorProps } from "../../interfaces/components/paginator";
import { getPages } from "../../shared/utils/helper";
import styles from "./paginator.module.scss";
import caretLeft from "../../assets/images/shared/icons/caretLeft.svg";
import caretRight from "../../assets/images/shared/icons/caretRight.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageClick,
}) => {
  const [firstList, setFirstList] = useState<number[]>([]);
  const [endList, setEndList] = useState<number[]>([]);
  useEffect(() => {
    const res = getPages(totalPages, currentPage);
    setFirstList(res.firstVisiblePages);
    setEndList(res.endVisiblePages);
  }, [currentPage, totalPages]);

  const goToPage = (page: number) => {
    if (page === currentPage) return;
    onPageClick(page);
  };
  return (
    <div
      className={`${styles["paginator-container"]} d-flex align-items-center ${totalPages > 1 ? "justify-content-between" : "justify-content-center"}`}
    >
      {totalPages > 1 && (
        <button
          aria-label={"go back"}
          onClick={() => goToPage(currentPage - 1)}
        >
          <LazyLoadImage
            src={caretLeft}
            alt={"go back"}
            width={30}
            height={30}
          />
        </button>
      )}
      <div className={"d-flex align-items-center justify-content-center"}>
        {firstList.map((item) => (
          <button
            key={item}
            aria-label={item.toString()}
            onClick={() => goToPage(item)}
          >
            <p className={currentPage === item ? styles["active"] : ""}>
              {item}
            </p>
          </button>
        ))}
        {endList.length > 0 && (
          <React.Fragment>
            <p className={styles["dots"]}>...</p>
            {endList.map((item) => (
              <button
                key={item}
                aria-label={item.toString()}
                onClick={() => goToPage(item)}
              >
                <p className={currentPage === item ? styles["active"] : ""}>
                  {item}
                </p>
              </button>
            ))}
          </React.Fragment>
        )}
      </div>
      {totalPages > 1 && (
        <button
          aria-label={"go forward"}
          onClick={() => goToPage(currentPage + 1)}
        >
          <LazyLoadImage
            src={caretRight}
            alt={"go forward"}
            width={30}
            height={30}
          />
        </button>
      )}
    </div>
  );
};

export default Paginator;
