import { DeviceTypes } from "../utils/enums";
import { useEffect, useState, useCallback, useMemo } from "react";
import useDeviceType from "./deviceType";

const useIsDesktop = (): boolean => {
  const desktopList = useMemo(
    () => [DeviceTypes.desktop, DeviceTypes.wideDesktop],
    [],
  );
  const deviceType = useDeviceType();
  const [desktop, setDesktop] = useState<boolean>(false);

  const updateDesktopStatus = useCallback(() => {
    setDesktop(desktopList.includes(deviceType));
  }, [desktopList, deviceType]);

  useEffect(() => {
    updateDesktopStatus();
  }, [deviceType, updateDesktopStatus]);

  return desktop;
};

export default useIsDesktop;
