import React from "react";
import { useTranslation } from "react-i18next";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import styles from "./storyVision.module.scss";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";
import { letsTalk } from "../../../../../shared/services/contactService";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";

const StoryVision: React.FC = () => {
  const { t } = useTranslation();
  const storyImage = useResponsiveImage(ImageKeys.animationStoryVision);
  const isDesktop = useIsDesktop();

  return (
    <section
      id={"story-vision"}
      className={`row ${styles["storyVision-container"]}`}
    >
      {isDesktop && (
        <LazyLoadImage
          containerClassName={"col-lg-6 col-md-12 col-sm-12 p-0"}
          src={storyImage}
          alt={"story vision"}
          placeholderSrc={getThumbnail(ImageKeys.animationStoryVision)}
        />
      )}
      <div className={`${styles["text-content"]} col-lg-6 col-md-12 col-sm-12`}>
        <p>{t("animation.storyVision1")}</p>
        <p>{t("animation.storyVision2")}</p>
      </div>
      {!isDesktop && (
        <LazyLoadImage
          containerClassName={"col-lg-6 col-md-12 col-sm-12 p-0"}
          src={storyImage}
          placeholderSrc={getThumbnail(ImageKeys.animationStoryVision)}
          alt={"story vision"}
        />
      )}
      <div className={"col-12 d-flex justify-content-center"}>
        <p className={`${styles["custom-letsTalk"]} letsTalk`}>
          {t("animation.haveStory")}{" "}
          <a href={"#contact"} onClick={(event) => letsTalk(event)}>
            {t("app.letsTalk")}
          </a>
        </p>
      </div>
    </section>
  );
};

export default StoryVision;
