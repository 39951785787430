import React from "react";
import { InsightTagProps } from "../../interfaces/components/insightTag";
import styles from "./insightTag.module.scss";
const InsightTag: React.FC<InsightTagProps> = ({ label }) => {
  return (
    <div className={styles["insightTag-container"]}>
      <p>{label}</p>
    </div>
  );
};

export default InsightTag;
