import React, { useCallback, useRef, useState } from "react";
import logoWhite from "../../../assets/images/shared/icons/logo-white.svg";
import logo from "../../../assets/images/shared/icons/logo.svg";
import styles from "./header.module.scss";
import sideMenu from "../../../config/sideMenu";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { screens } from "../../../config/routes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import closeIcon from "../../../assets/images/shared/icons/close.svg";
import drawer from "../../../assets/images/shared/icons/sideMenu.svg";
import { scrollToContact } from "../../../shared/services/contactService";
import { mainSectionSocialMedia } from "../../../config/mainSection";
import { openLink } from "../../../shared/utils/helper";
import Button from "../../../components/button";
import useClickOutside from "../../../shared/hooks/useClickOutside";
import { isBrowserDefined } from "../../../shared/services/browserService";
import useIsDesktop from "../../../shared/hooks/isDesktop";

const lightRoutes = [screens.insightDetails];
const Header = () => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isDesktop = useIsDesktop();

  /**
   * State to manage the opening/closing of the side menu.
   */
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  useClickOutside(dropdownRef, () => {
    isDropdownOpen && isDesktop && setDropdownOpen(false);
  });

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };
  /**
   * Toggles the visibility of the side menu.
   */
  const toggleMenu = useCallback(() => {
    const newState = !isSideMenuOpen;
    setIsSideMenuOpen(newState);
    if (isDropdownOpen) {
      setDropdownOpen(false);
    }
  }, [isSideMenuOpen, isDropdownOpen]);

  const location = useLocation();

  const isLightHeader = useCallback(() => {
    const isLightResult = lightRoutes.map((route) =>
      location.pathname.includes(route),
    );
    return isLightResult.includes(true);
  }, [location]);

  const goToContact = () => {
    toggleMenu();
    scrollToContact();
  };

  const getDropDownStyle = useCallback(() => {
    if (isDropdownOpen) return styles["fade-in"];
    if (!isBrowserDefined() || !dropdownRef.current) {
      return "";
    }
    const currentOpacity = window.getComputedStyle(dropdownRef.current).opacity;
    if (+currentOpacity === 0) return "";
    return styles["fade-out"];
  }, [dropdownRef, isDropdownOpen]);

  return (
    <header className={isLightHeader() ? styles["light-header"] : ""}>
      <div className={styles["navbar"]}>
        <NavLink to={"/"}>
          <LazyLoadImage
            className={`${styles["logo"]} relative`}
            src={isLightHeader() ? logo : logoWhite}
            width={"auto"}
            height={"73"}
            alt={"CanGuru Logo"}
            visibleByDefault
          />
        </NavLink>
        <button
          className={`${styles["drawer-btn"]} ${isLightHeader() ? styles["light-drawer"] : styles["dark-drawer"]} d-lg-none`}
          onClick={toggleMenu}
          aria-label={"Toggle side menu"}
        >
          <img src={drawer} width={32.34} height={34} alt={"toggle menu"} />
        </button>
        <ul
          className={`${styles["list-container"]} d-lg-flex align-items-center d-md-none d-sm-none d-xs-none`}
        >
          {sideMenu.map((item, idx) => (
            <li
              className={`${styles["list-item"]} ${item.children?.length && isDropdownOpen ? styles["active"] : ""}`}
              key={item.path}
            >
              {item.children?.length ? (
                <React.Fragment key={item.path}>
                  <button
                    className={!isDropdownOpen ? styles["link-item"] : ""}
                    aria-label={t(item.label)}
                    disabled={isDropdownOpen}
                    onClick={toggleDropdown}
                  >
                    {t(item.label)}
                  </button>
                  <div
                    ref={dropdownRef}
                    className={`${styles["navbar-sub-container"]} ${getDropDownStyle()} ${isLightHeader() ? styles["light-navbar-sub"] : ""}`}
                  >
                    {item.children.map((sideMenuItem) => (
                      <NavLink
                        className={"relative"}
                        to={sideMenuItem.path}
                        key={sideMenuItem.path}
                        onClick={toggleDropdown}
                      >
                        {t(sideMenuItem.label)}
                      </NavLink>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                <NavLink className={styles["link-item"]} to={item.path}>
                  {t(item.label)}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={isSideMenuOpen ? styles["side-menu-open"] : ""}>
        <div
          onClick={toggleMenu}
          className={`${styles["overlay"]} d-lg-none`}
        ></div>
        <div
          className={`d-lg-none d-md-block d-sm-block ${styles["side-menu"]}`}
        >
          <div className={styles["side-menu-items-container"]}>
            <div
              className={`${styles["side-menu-header"]} d-flex align-items-center justify-content-end`}
            >
              <button
                className={`${styles["drawer-btn"]} d-lg-none`}
                onClick={toggleMenu}
                aria-label={"Toggle side menu"}
              >
                <img
                  src={closeIcon}
                  width={26.87}
                  height={26.87}
                  alt={"close menu"}
                />
              </button>
            </div>
            {sideMenu.map((item) =>
              item.children?.length ? (
                <div key={item.path}>
                  <button
                    className={`${styles["dropdown-button"]} ${styles["side-menu-item"]} d-flex align-items-center justify-content-between`}
                    onClick={() => toggleDropdown()}
                  >
                    <p>{t(item.label)}</p>
                    <div
                      className={`${styles["dropdown-icon"]} ${isDropdownOpen ? styles["open"] : ""}`}
                    ></div>
                  </button>
                  <div
                    className={`${styles["dropdown-menu"]} ${isDropdownOpen ? styles["show"] : ""}`}
                  >
                    {item.children.map((sub) => (
                      <NavLink
                        key={sub.path}
                        to={sub.path}
                        onClick={toggleMenu}
                        className={({ isActive }) =>
                          `${styles["side-menu-item"]} ${isActive && !sub.path.includes("#") ? styles["active"] : ""}`
                        }
                      >
                        <p>{t(sub.label)}</p>
                      </NavLink>
                    ))}
                  </div>
                </div>
              ) : (
                <NavLink
                  key={item.path}
                  to={item.path}
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `${styles["side-menu-item"]} ${isActive && !item.path.includes("#") ? styles["active"] : ""}`
                  }
                >
                  <p>{t(item.label)}</p>
                </NavLink>
              ),
            )}
            <div
              className={`d-flex align-items-center justify-content-start ${styles["socialMedia-buttons"]}`}
            >
              {mainSectionSocialMedia.map((item) => (
                <button
                  key={item.iconName}
                  onClick={() => openLink(item.link)}
                  className={`d-flex justify-content-center align-items-center`}
                  aria-label={item.iconName}
                >
                  <item.icon size={30} />
                </button>
              ))}
            </div>
            <Button
              className={styles["consultation-btn"]}
              label={t("app.freeConsultation")}
              onClick={goToContact}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
