import React from "react";
import { useTranslation } from "react-i18next";
import developmentHubList from "../../../../../shared/static/developmentHub";
import styles from "./developmentHub.module.scss";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";
import { letsTalk } from "../../../../../shared/services/contactService";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";
const DevelopmentHub = () => {
  const { t } = useTranslation();
  const background = useResponsiveImage(ImageKeys.mobileAppsBackground);
  const phoneImage = useResponsiveImage(ImageKeys.mobileAppsPhone);
  const isDesktop = useIsDesktop();

  return (
    <section
      id={"development-hub"}
      className={`${styles["developmentHub-container"]} relative`}
    >
      <LazyLoadImage
        className={"background-image"}
        src={background}
        placeholderSrc={getThumbnail(ImageKeys.mobileAppsBackground)}
        alt={"mobile details section"}
      />
      {!isDesktop && (
        <img
          src={phoneImage}
          className={styles["phone-image"]}
          alt={"phone details"}
          width={"auto"}
          height={"auto"}
        />
      )}
      <div className={styles["padding"]}>
        {developmentHubList.map((item, idx) => (
          <div
            className={`relative ${styles["hubItem-container"]} ${idx % 2 === 0 ? styles["left"] : styles["right"]}`}
          >
            <h2>{t(item.title)}</h2>
            <p>{t(item.description)}</p>
            <p className={styles["letsTalk"]}>
              {t("mobileApps.gotBrilliantIdea")}{" "}
              <a href={"#contact"} onClick={(event) => letsTalk(event)}>
                {t("app.letsTalk")}
              </a>
            </p>
          </div>
        ))}
        <p className={styles["note"]}>{t("mobileApps.note")}</p>
      </div>
    </section>
  );
};

export default DevelopmentHub;
