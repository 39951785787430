import { useState, useEffect, useCallback, useRef } from "react";
import { getCaseStudyList } from "./actions";
import { Project } from "../../../interfaces/api/caseStudy";

const useCaseStudy = (categoryIds: number[], initialPage = 1, perPage = 10) => {
  const [isLoading, setIsLoading] = useState(false);
  const [caseStudy, setCaseStudy] = useState<Project[]>([]);
  const [isError, setIsError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const initialFetch = useRef(true); // New useRef to manage initial fetch
  const page = useRef(initialPage); // New useRef to manage initial fetch

  const fetchDetails = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const res = await getCaseStudyList(categoryIds, page.current, perPage);
      if (res && res.length) {
        setCaseStudy((prev) => [...prev, ...res]);
        page.current = page.current + 1;
        setHasMore(res.length === perPage);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, categoryIds, page, perPage]);

  useEffect(() => {
    page.current = 1;
    setHasMore(true);
    setCaseStudy([]);
    fetchDetails();
  }, [categoryIds]);

  return { isLoading, caseStudy, isError, hasMore, fetchMore: fetchDetails };
};

export default useCaseStudy;
