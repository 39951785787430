import React, {useCallback} from "react";
import styles from "./caseStudyListItem.module.scss";
import {getCaseStudyTypeById, truncateText} from "../../shared/utils/helper";
import {CaseStudyItemProps} from "../../interfaces/components/caseStudyItem";
import useIsDesktop from "../../shared/hooks/isDesktop";
import {useTranslation} from "react-i18next";
import ResponsiveMockup from "../responsiveMockup";
import mockupConfigs from "../../shared/static/mockupConfig";
import {NavLink} from "react-router-dom";
import CaseTinyImage from "../caseTinyImage";

const CaseStudyListItem: React.FC<CaseStudyItemProps> = ({
                                                             title,
                                                             description,
                                                             images,
                                                             className,
                                                             slug,
                                                             col = "col-lg-4 col-md-6 col-sm-12",
                                                             logo,
                                                             headerId,
                                                         }) => {
    const isDesktop = useIsDesktop();
    const {t} = useTranslation();

    return (
        <NavLink
            draggable={false}
            to={`/case-study/${slug}`}
            className={`${col} p-0 ${styles["caseLink-container"]}`}
        >
            <div
                className={`${styles["caseStudyItem-container"]} ${className} relative row align-items-center`}
            >
                <div className={`d-flex flex-column justify-content-between col-6`}>
                        <div className={`d-flex flex-column justify-content-center ${styles['text-content']}`}>
                        <h2 className={'mt-0'}>{title}</h2>
                        <p>{truncateText(description, isDesktop ? 43 : 43)}</p>
                    </div>
                    <button>{t("home.explore")}</button>
                </div>
                <div className={"p-0 col-6"}>
                    <CaseTinyImage images={images} logo={logo} headerId={headerId}/>
                </div>
            </div>
        </NavLink>
    );
};

export default CaseStudyListItem;
