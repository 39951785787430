import { OurProcessItemProps } from "../../interfaces/components/ourProcessItem";

const ourProcessList: OurProcessItemProps[] = [
  {
    title: "home.ourProcessOneTitle",
    description: "home.ourProcessOneDescription",
    index: 1,
  },
  {
    title: "home.ourProcessTwoTitle",
    description: "home.ourProcessTwoDescription",
    index: 2,
  },
  {
    title: "home.ourProcessThreeTitle",
    description: "home.ourProcessThreeDescription",
    index: 3,
  },
  {
    title: "home.ourProcessFourTitle",
    description: "home.ourProcessFourDescription",
    index: 4,
  },
];

export default ourProcessList;
