import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import socialLinks from "./links";
import { RiInstagramFill } from "react-icons/ri";
import { SocialMediaItems } from "../interfaces/config/socialMedia";

const socialMediaItems: SocialMediaItems = {
  facebook: {
    icon: FaFacebook,
    iconName: "facebook",
    link: socialLinks.facebook,
  },
  linkedin: {
    icon: FaLinkedin,
    iconName: "linkedin",
    link: socialLinks.linkedin,
  },
  youtube: {
    icon: FaYoutube,
    iconName: "youtube",
    link: socialLinks.youtube,
  },
  instagram: {
    icon: RiInstagramFill,
    iconName: "instagram",
    link: socialLinks.instagram,
  },
};

export default socialMediaItems;
