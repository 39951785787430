import React from "react";
import Contact from "../../../components/contact";
import useResponsiveImage from "../../hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import styles from "./bottomSection.module.scss";
import { BottomSectionProps } from "../../../interfaces/shared/components/bottomSection";
import LazyLoadImage from "../lazyLoadImage";
import { getThumbnail } from "../../utils/imageHelper";

const BottomSection: React.FC<BottomSectionProps> = ({ className = "" }) => {
  const robotBackground = useResponsiveImage(
    ImageKeys.transparentRobotBackground,
  );

  return (
    <div
      className={`${styles["bottomSection-container"]} relative ${className}`}
    >
      <LazyLoadImage
        className={styles["robot-image"]}
        src={robotBackground}
        placeholderSrc={
          getThumbnail(ImageKeys.transparentRobotBackground) || ""
        }
        alt={"CanGuru Contact"}
      />
      <div className={styles["overlay"]}></div>
      <div className={`${styles["bottom-content"]} relative`}>
        <Contact />
      </div>
    </div>
  );
};

export default BottomSection;
