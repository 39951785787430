import {
  BlogDetails,
  BlogsScreenResponse,
} from "../../../interfaces/api/blogs";
import { services } from "../../../api";

export const getBlogs = async (
  page: number,
): Promise<BlogsScreenResponse | null> => {
  try {
    const res = await services.insights.getBlogs(page);
    if (res.result !== "success") {
      throw new Error("Blogs error");
    }
    return {
      current_page: res.content.current_page,
      total: res.content.total_pages,
      data: res.content.data,
    };
  } catch (e) {
    return null;
  }
};

export const getBlogDetails = async (
  slug: string,
): Promise<BlogDetails | null> => {
  try {
    const res = await services.insights.blogDetails(slug);
    if (res.result !== "success") {
      throw new Error("Blogs error");
    }
    return res.content;
  } catch (e) {
    return null;
  }
};
