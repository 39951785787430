import { CategoryTypes } from "../../interfaces/components/caseStudyItem";

export const allCategoryId = 100;

export const getCaseStudyBadge = (categoryId: number) => {
  const list = [
    "appsBadge",
    "web",
    "software",
    "userExperienceBadge",
    "design",
    "digitalMarketing",
    "animation",
  ];
  return `caseStudy.${list[+categoryId - 1]}`;
};

export const getCaseStudyPlaceholder = (type: CategoryTypes): string => {
  let width = 1280;
  let height = 720;

  if (type === "mobile") {
    height = 2556;
    width = 1179;
  }

  return `https://placehold.co/${width}x${height}`;
};
