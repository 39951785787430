import { useState, useEffect } from "react";
import { Project } from "../../interfaces/api/caseStudy";
import { services } from "../../api";
import environment from "../../environment/environment";
import { FeaturedCaseStudyHookResponse } from "../../interfaces/shared/hooks/useFeaturedProjects";

const useFeaturedProjects = (): FeaturedCaseStudyHookResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [featuredProjects, setFeaturedProjects] = useState<Project[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setIsLoading(true);
        const res = await services.caseStudy.getFeaturedProjects();
        if (res.result === "success") {
          setFeaturedProjects(res.content.data || []);
        } else {
          setIsError(true);
        }
      } catch (err) {
        if (environment.debug)
          console.error("Error fetching featured projects:", err);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Check if the projects are already loaded
    if (!featuredProjects.length) {
      fetchProjects();
    }
  }, []); // Empty dependency array means this effect will only run once when the component mounts

  return { isLoading, featuredProjects, isError };
};

export default useFeaturedProjects;
