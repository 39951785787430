import React from "react";
import styles from "./digitalServices.module.scss";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import { useTranslation } from "react-i18next";
import digitalMarketingList from "../../../../../shared/static/digitalMarketing";
import BrandingItem from "../../../../../components/brandingItem";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";
const DigitalServices = () => {
  const { t } = useTranslation();
  const background = useResponsiveImage(ImageKeys.digitalMarketingBackground);

  return (
    <section
      id={"digital-services"}
      className={`${styles["digitalServices-container"]} relative`}
    >
      <LazyLoadImage
        className={`background-image ${styles["custom-background-image"]}`}
        src={background}
        placeholderSrc={getThumbnail(ImageKeys.digitalMarketingBackground)}
        alt={"digital marketing details section"}
        isFirstImage
      />
      <div className={`${styles["page-content"]} relative`}>
        <p className={`mt-0 ${styles["description"]}`}>
          {t("digitalMarketing.services")}
        </p>
        {digitalMarketingList.map((item, idx) => (
          <div key={item.label}>
            <h2>{t(`digitalMarketing.${item.label}`)}</h2>
            <div
              className={`row justify-content-center ${styles["list-container"]} ${idx === 1 ? styles["pb-20"] : ""}`}
            >
              {item.list.map((val) => (
                <div className={"col-lg-6 col-md-12 col-sm-12"}>
                  <BrandingItem
                    containerClass={styles["custom-brand"]}
                    key={val}
                    label={t(`digitalMarketing.${val}`)}
                  ></BrandingItem>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DigitalServices;
