import React, { useEffect, useState } from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import styles from "./caseStudyList.module.scss";
import CaseListSection from "./caseListSection";
import { useTranslation } from "react-i18next";
import useDeviceType from "../../../../shared/hooks/deviceType";
import { DeviceTypes } from "../../../../shared/utils/enums";
import useCategories from "./useCategories";
import CaseListLoader from "./caseListLoader";
import CategoryItem from "../../../../components/categoryItem";
import { allCategoryId } from "../../../../shared/static/caseStudy";

const CaseStudyList = () => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const { categories, isLoading, isError } = useCategories();
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  useEffect(() => {
    if (categories.length) {
      setSelectedCategories([categories[0].id]); // Default to the first category
    }
  }, [categories]);

  const handleCategorySelection = (id: number) => {
    setSelectedCategories((prev) => {
      let temp = prev;
      if (prev.includes(allCategoryId)) {
        temp = temp.filter((item) => item !== allCategoryId);
      }
      if (id === allCategoryId) {
        return [id];
      }
      if (temp.includes(id)) {
        return temp.length > 1 ? temp.filter((item) => item !== id) : temp;
      } else {
        return [...temp, id];
      }
    });
  };

  return (
    <div className={"gradient-background"}>
      {generateMeta({
        title: metaData.caseStudy.title,
        description: metaData.caseStudy.description,
      })}
      <div className={styles["caseStudyList-container"]}>
        <h1 className={styles["mainSection-title"]}>{t("caseStudy.title")}</h1>
        {isLoading ? (
          <CaseListLoader />
        ) : (
          <div>
            <div
              className={`${styles["categoryList-container"]} d-flex align-items-center`}
            >
              {categories.map((item) => (
                <CategoryItem
                  key={item.id}
                  label={item.name}
                  onClick={() => handleCategorySelection(item.id)}
                  selected={selectedCategories.includes(item.id)}
                />
              ))}
            </div>
            {selectedCategories.length > 0 && (
              <CaseListSection
                marginTop={deviceType === DeviceTypes.mobile ? "0px" : "0px"}
                categoryIds={selectedCategories}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseStudyList;
