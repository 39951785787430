import React, { useEffect, useState } from "react";
import { Blog, Pagination } from "../../../../interfaces/api/blogs";
import { getBlogs } from "../actions";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import InsightItem from "../../../../components/insightItem";
import { extractContent } from "../../../../shared/utils/helper";
import styles from "./insightsList.module.scss";
import InsightsListLoader from "../insightsListLoader";
import Paginator from "../../../../components/paginator";
import useIsDesktop from "../../../../shared/hooks/isDesktop";
import BottomSection from "../../../../shared/components/bottomSection";
import { isBrowserDefined } from "../../../../shared/services/browserService";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";

const Insights = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Blog[]>([]);
  const isDesktop = useIsDesktop();
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    total: 1,
  });

  const getData = async (page = 1) => {
    if (isBrowserDefined()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsLoading(true);
    const res = await getBlogs(page);
    setIsLoading(false);
    if (!res) return;
    setData(res.data);
    setPagination({
      currentPage: res.current_page,
      total: res.total,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`gradient-background`}>
      {generateMeta({
        title: metaData.insights.title,
        description: metaData.insights.description,
      })}
      <div className={styles["insights-container"]}>
        <MainSection
          label={"insights.title"}
          bigTitle
          transparentBackground
          showConsultationButton={false}
          containerClass={styles["mainSection-container"]}
          labelClass={styles["mainSection-title"]}
          descriptionClass={styles["mainSection-description"]}
          description={"insights.description"}
          smallLabelStyle
          customClassName={styles["custom-container"]}
        />
        <div
          className={`${styles["insight-content"]} relative d-flex flex-column align-items-center`}
        >
          {isLoading ? (
            <InsightsListLoader />
          ) : (
            <React.Fragment>
              {data.map((item) => (
                <InsightItem
                  key={item.id}
                  title={item.title}
                  navigateTo={"/insights/" + item.slug}
                  originalImage={item.image.desktop}
                  placeholder={item.image.mobile}
                  description={extractContent(
                    item.content,
                    isDesktop ? 50 : 10,
                  )}
                  image={item.image[isDesktop ? "desktop" : "mobile"]}
                />
              ))}
              {(pagination.total > 1 || pagination.currentPage > 1) && (
                <Paginator
                  currentPage={pagination.currentPage}
                  totalPages={pagination.total}
                  onPageClick={(page) => getData(page)}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className={`case-bottom-container`}>
          <CaseStudyCarousel />
        </div>
        <BottomSection />
      </div>
    </div>
  );
};

export default Insights;
