import React from "react";
import Logo from "../../../assets/images/shared/icons/logo.svg";
import styles from "./footer.module.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { footer } from "../../../config/footer";
import { SideMenuItem } from "../../../interfaces/config/sideMenu";
import { SocialIconItem } from "../../../interfaces/config/socialMedia";
import { openLink } from "../../../shared/utils/helper";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={`${styles["footer"]} row`}>
      <div className={"col-md-3 col-sm-4"}>
        <img
          src={Logo}
          className={styles["logo"]}
          alt={"CanGuru footer logo"}
          width={"auto"}
          height={"auto"}
        />
        <div className={"d-sm-none d-md-block"}>
          <p>
            {t("footer.copyright")} {new Date().getFullYear()}
          </p>
          <p>{t("footer.companyName")}</p>
          <p>{t("footer.location")}</p>
        </div>
      </div>
      <div className={styles["menu-list"]}>
        {Object.keys(footer.menu).map((key: any) => (
          <div className={`${styles["sub-list"]}`} key={key}>
            {footer.menu[key as keyof typeof footer.menu].map(
              (item: SideMenuItem) => (
                <NavLink to={item.path} key={item.path}>
                  {t(item.label)}
                </NavLink>
              ),
            )}
          </div>
        ))}
      </div>
      <div className={`${styles["social-list"]}`}>
        {Object.keys(footer.socialMedia).map((key) => (
          <div className={`${styles["icon-list"]}`} key={key}>
            {footer.socialMedia[key as keyof typeof footer.socialMedia]?.map(
              (item: SocialIconItem) => (
                <button
                  key={item.iconName}
                  aria-label={item.iconName}
                  onClick={() => openLink(item.link)}
                >
                  <item.icon className={styles[item.iconName]} />
                </button>
              ),
            )}
          </div>
        ))}
      </div>
      <div
        className={`d-lg-none d-md-flex d-sm-flex align-items-center ${styles["bottom-social"]}`}
        style={{ gap: "24px", width: "100%" }}
      >
        {footer.mobileSocialMedia.map((item) => (
          <button
            className={styles["social-button"]}
            key={item.iconName}
            aria-label={item.iconName}
            onClick={() => openLink(item.link)}
          >
            <item.icon className={styles[item.iconName]} />
          </button>
        ))}
      </div>
      <div className={"d-lg-none d-md-none d-sm-block"}>
        <p className={styles["company-mobile"]}>
          {t("footer.copyright")} {new Date().getFullYear()}{" "}
          {t("footer.companyName")} <br />
          {t("footer.location")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
