import angular from "../../assets/images/shared/home/technologies/angular.svg";
import php from "../../assets/images/shared/home/technologies/php.svg";
import cSharp from "../../assets/images/shared/home/technologies/cSharp.svg";
import python from "../../assets/images/shared/home/technologies/python.svg";
import html from "../../assets/images/shared/home/technologies/html.svg";
import react from "../../assets/images/shared/home/technologies/react.svg";
import js from "../../assets/images/shared/home/technologies/js.svg";

const ourTechnicalExpertiseList = [
  {
    icon: angular,
    title: "webSolutions.angularTitle",
    description: "webSolutions.angularDescription",
  },
  {
    icon: php,
    title: "webSolutions.phpTitle",
    description: "webSolutions.phpDescription",
  },
  {
    icon: cSharp,
    title: "webSolutions.cSharpTitle",
    description: "webSolutions.cSharpDescription",
  },
  {
    icon: python,
    title: "webSolutions.pythonTitle",
    description: "webSolutions.pythonDescription",
  },
  {
    icon: html,
    title: "webSolutions.htmlTitle",
    description: "webSolutions.htmlDescription",
  },
  {
    icon: react,
    title: "webSolutions.reactTitle",
    description: "webSolutions.reactDescription",
  },
  {
    icon: js,
    title: "webSolutions.javascriptTitle",
    description: "webSolutions.javascriptDescription",
  },
];

export default ourTechnicalExpertiseList;
