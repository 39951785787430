import React from "react";
import Header from "./header";
import Footer from "./footer";
import { CustomLayoutProps } from "../../interfaces/customLayout";
import useScrollPosition from "../../shared/hooks/scrollPosition";
import { IoIosArrowUp } from "react-icons/io";
import styles from "./customLayout.module.scss";
import { isBrowserDefined } from "../../shared/services/browserService";
import { ToastContainer } from "react-toastify";
import Loader from "../../connected-components/loader";
const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  const { y } = useScrollPosition();
  const isScrollTopVisible = y > 100;

  const scrollToTop = () => {
    if (!isBrowserDefined()) {
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={styles["layout-container"]}>
      <ToastContainer
        stacked
        position={"bottom-left"}
        toastStyle={{
          borderRadius: 12,
          background: "rgba( 255, 255, 255, 0.8 )",
          backdropFilter: "blur( 20px )",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
        }}
      />
      <Header />
      {children}
      <Footer />
      <div className={styles["buttons-container"]}>
        <button
          className={`${styles["circle-button"]} ${styles["scroll-to-top"]} ${isScrollTopVisible ? styles["visible"] : ""}`}
          onClick={scrollToTop}
          aria-label={"go back to top"}
        >
          <IoIosArrowUp size={20} color={"white"} />
        </button>
      </div>
      <Loader />
    </div>
  );
};

export default CustomLayout;
