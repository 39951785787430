import { Helmet } from "react-helmet";
import { extractContent } from "../utils/helper";
interface MetaData {
  title: string;
  slug?: string;
  description?: string;
  image?: string;
  keywords?: string[];
}

const generateMeta = ({
  title,
  slug = "",
  description = "main description",
  image = "",
  keywords = [],
}: MetaData): JSX.Element => {
  const descriptionText = extractContent(description);
  const updatedTime = Math.floor(Date.now() / 1000).toString();

  return (
    <Helmet>
      {/* Keywords */}
      {keywords.length > 0 && (
        <meta
          name="keywords"
          content={keywords.map((item) => item).join(", ")}
        />
      )}
      <title>{title}</title>

      {/* Normal */}
      <meta name="title" content={title} />
      <meta name="description" content={descriptionText} />
      {image && <meta name="image" content={image} />}
      <meta name="updated_time" content={updatedTime} />

      {/* Google */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={descriptionText} />
      {image && <meta itemProp="image" content={image} />}

      {/* Facebook + LinkedIn + WhatsApp */}
      <meta
        property="og:url"
        content={`https://canguru.com.au/blogs/${slug}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descriptionText} />
      {image && <meta property="og:image" content={image} />}
      <meta name="og:updated_time" content={updatedTime} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={descriptionText} />
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:updated_time" content={updatedTime} />
    </Helmet>
  );
};

export default generateMeta;
