import React from "react";
import styles from "./caseStudyDetailsLoader.module.scss";

const CaseStudyDetailsLoader: React.FC<{ loaded: boolean }> = ({ loaded }) => {
  return (
    <div>
      <div
        className={`${styles["caseStudy-loader"]} ${!loaded && styles["full-screen-loader"]}`}
      >
        <div className={styles["loader"]}></div>
      </div>
      <div className={styles["white-space"]}></div>
    </div>
  );
};

export default CaseStudyDetailsLoader;
