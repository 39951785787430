import React from "react";
import styles from "./animation.module.scss";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import welcomeImage from "../../../../assets/images/shared/animation/welcome.svg";
import StoryVision from "./storyVision";
import WeCanDo from "./weCanDo";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";

const Animation = () => {
  return (
    <div className={styles["animation-container"]}>
      {generateMeta({
        title: metaData.webSolutions.title,
        description: metaData.webSolutions.description,
      })}
      <MainSection
        gradientBackground
        smallLabelStyle
        label={"animation.title"}
        image={welcomeImage}
        description={"animation.description"}
        showConsultationButton
        labelClass={styles["main-section-label"]}
        descriptionClass={styles["main-section-description"]}
        imageClass={styles["main-section-image"]}
        consultationButtonClass={styles["custom-button"]}
        bottomContainerClass={styles["custom-bottom-container"]}
      />
      <StoryVision />
      <WeCanDo />
      <div className={`case-bottom-container ${styles["case-study"]}`}>
        <CaseStudyCarousel />
      </div>
      <BottomSection />
    </div>
  );
};

export default Animation;
