export enum DeviceTypes {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  wideDesktop = "wideDesktop",
}

export enum CategoryIds {
  mobile = 1,
  web = 2,
  software = 3,
  ux = 4,
  branding = 5,
}
