import React from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import welcomeImage from "../../../../assets/images/shared/userExperienceDesign/welcome.svg";
import styles from "./userExperienceDesign.module.scss";
import WeDesignForHuman from "./weDesignForHuman";
import CustomProcess from "../../../../components/customProcess";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsiveImage from "../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../assets/images";
import ourUxProcessList from "../../../../shared/static/ourUxProcess";
import { useTranslation } from "react-i18next";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";
const UserExperienceDesign = () => {
  const background = useResponsiveImage(
    ImageKeys.userExperienceDesignBackground,
  );
  const { t } = useTranslation();

  return (
    <div className={styles["userExperienceDesign-container"]}>
      {generateMeta({
        title: metaData.userExperienceDesign.title,
        description: metaData.userExperienceDesign.description,
      })}
      <MainSection
        gradientBackground
        labelClass={styles["custom-label"]}
        label={"userExperienceDesign.title"}
        image={welcomeImage}
        description={"userExperienceDesign.description"}
        descriptionClass={styles["custom-description"]}
        imageClass={styles["custom-image"]}
        showConsultationButton
      />
      <div className={`${styles["bottom-container"]} relative`}>
        <LazyLoadImage
          className={"background-image"}
          src={background}
          alt={"gradient"}
          width={"100%"}
          height={"auto"}
        />
        <WeDesignForHuman />
        <CustomProcess
          ourProcessList={ourUxProcessList}
          leftTitle={t("userExperienceDesign.ourUX")}
          rightTitle={t("userExperienceDesign.process")}
          leftTitleClassName={styles["left-title"]}
          rightTitleClassName={styles["right-title"]}
          borderBoxClass={styles["custom-border"]}
          className={styles["processItem-container"]}
          processItemClass={styles["customProcess-item"]}
          sectionId={"our-ux-process"}
          homeType={false}
        />
      </div>
      <div className={`case-bottom-container ${styles["case-study"]}`}>
        <CaseStudyCarousel />
      </div>
      <BottomSection />
    </div>
  );
};

export default UserExperienceDesign;
