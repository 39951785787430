export const screens = {
  home: "/",
  caseStudy: "case-study",
  caseStudyDetails: "case-study/",
  insights: "insights",
  insightDetails: "insights/",
  about: "about",
  mobileApps: "services/mobile-app",
  industrySpecificSoftware: "/services/industry-specific-software",
  webSolutions: "/services/web-solutions",
  userExperienceDesign: "/services/user-experience-design",
  branding: "/services/branding",
  digitalMarketing: "/services/digital-marketing",
  animation: "/services/animation",
  contactUs: "contact-us",
};
