import { ImageItem } from "../../interfaces/assets/images";

import MainSectionBackgroundDesktop from "./desktop/mainSection/background.webp";
import MainSectionBackgroundTablet from "./tablet/mainSection/background.webp";
import MainSectionBackgroundMobile from "./mobile/mainSection/background.webp";
import MainSectionBackgroundThumbnail from "./thumbnails/mainSection/background.webp";

import HomeCloudsBackgroundDesktop from "./desktop/home/clouds.webp";
import HomeCloudsBackgroundTablet from "./tablet/home/clouds.webp";
import HomeCloudsBackgroundMobile from "./mobile/home/clouds.webp";
import HomeCloudsBackgroundThumbnail from "./thumbnails/home/clouds.webp";

import HomeBackgroundDesktop from "./desktop/home/background.webp";
import HomeBackgroundTablet from "./tablet/home/background.webp";
import HomeBackgroundMobile from "./mobile/home/background.webp";
import HomeBackgroundThumbnail from "./thumbnails/home/background.webp";

import TransparentRobotBackgroundDesktop from "./desktop/robot.webp";
import TransparentRobotBackgroundTablet from "./tablet/robot.webp";
import TransparentRobotBackgroundMobile from "./mobile/robot.webp";
import TransparentRobotBackgroundThumbnail from "./thumbnails/robot.webp";

import MobileAppsPhoneDesktop from "./desktop/mobileApps/phone.webp";
import MobileAppsPhoneTablet from "./tablet/mobileApps/phone.webp";
import MobileAppsPhoneMobile from "./mobile/mobileApps/phone.webp";

import MobileAppsBackgroundDesktop from "./desktop/mobileApps/background.webp";
import MobileAppsBackgroundTablet from "./tablet/mobileApps/background.webp";
import MobileAppsBackgroundMobile from "./mobile/mobileApps/background.webp";
import MobileAppsBackgroundThumbnail from "./thumbnails/mobileApps/background.webp";

import WebSolutionsBackgroundDesktop from "./desktop/webSolutions/background.webp";
import WebSolutionsBackgroundTablet from "./tablet/webSolutions/background.webp";
import WebSolutionsBackgroundMobile from "./mobile/webSolutions/background.webp";
import WebSolutionsBackgroundThumbnail from "./thumbnails/webSolutions/background.webp";

import WebSolutionsTabletDesktop from "./desktop/webSolutions/tablet.webp";
import WebSolutionsTabletTablet from "./tablet/webSolutions/tablet.webp";
import WebSolutionsTabletMobile from "./mobile/webSolutions/tablet.webp";

import WebSolutionsLaptopDesktop from "./desktop/webSolutions/laptop.webp";
import WebSolutionsLaptopTablet from "./tablet/webSolutions/laptop.webp";
import WebSolutionsLaptopMobile from "./mobile/webSolutions/laptop.webp";

import AnimationStoryVisionDesktop from "./desktop/animation/storyVision.webp";
import AnimationStoryVisionTablet from "./tablet/animation/storyVision.webp";
import AnimationStoryVisionMobile from "./mobile/animation/storyVision.webp";
import AnimationStoryVisionThumbnail from "./thumbnails/animation/storyVision.webp";

import AnimationBackgroundDesktop from "./desktop/animation/background.webp";
import AnimationBackgroundTablet from "./tablet/animation/background.webp";
import AnimationBackgroundMobile from "./mobile/animation/background.webp";

import IndustrySoftwarePC1Desktop from "./desktop/industrySoftware/pc1.webp";
import IndustrySoftwarePC1Tablet from "./tablet/industrySoftware/pc1.webp";
import IndustrySoftwarePC1Mobile from "./mobile/industrySoftware/pc1.webp";

import IndustrySoftwarePC2Desktop from "./desktop/industrySoftware/pc2.webp";
import IndustrySoftwarePC2Tablet from "./tablet/industrySoftware/pc2.webp";
import IndustrySoftwarePC2Mobile from "./mobile/industrySoftware/pc2.webp";

import IndustrySoftwareBackgroundDesktop from "./desktop/industrySoftware/background.webp";
import IndustrySoftwareBackgroundTablet from "./tablet/industrySoftware/background.webp";
import IndustrySoftwareBackgroundMobile from "./mobile/industrySoftware/background.webp";
import IndustrySoftwareBackgroundThumbnail from "./thumbnails/industrySoftware/background.webp";

import UserExperienceDesignBackgroundDesktop from "./desktop/userExperienceDesign/background.webp";
import UserExperienceDesignBackgroundTablet from "./tablet/userExperienceDesign/background.webp";
import UserExperienceDesignBackgroundMobile from "./mobile/userExperienceDesign/background.webp";

import UserExperienceDesignPeopleDesktop from "./desktop/userExperienceDesign/people.webp";
import UserExperienceDesignPeopleTablet from "./tablet/userExperienceDesign/people.webp";
import UserExperienceDesignPeopleMobile from "./mobile/userExperienceDesign/people.webp";

import UserExperienceDesignRobotBackgroundDesktop from "./desktop/userExperienceDesign/robot.webp";
import UserExperienceDesignRobotBackgroundTablet from "./tablet/userExperienceDesign/robot.webp";

import BrandingBackgroundDesktop from "./desktop/branding/background.webp";
import BrandingBackgroundTablet from "./tablet/branding/background.webp";
import BrandingBackgroundMobile from "./mobile/branding/background.webp";
import BrandingBackgroundThumbnail from "./thumbnails/branding/background.webp";

import DigitalMarketingBackgroundDesktop from "./desktop/digitalMarketing/background.webp";
import DigitalMarketingBackgroundTablet from "./tablet/digitalMarketing/background.webp";
import DigitalMarketingBackgroundMobile from "./mobile/digitalMarketing/background.webp";
import DigitalMarketingBackgroundThumbnail from "./thumbnails/digitalMarketing/background.webp";

import AustraliaMobile from "./mobile/about/au.webp";
import AustraliaDesktop from "./desktop/about/au.webp";

export enum ImageKeys {
  mainSectionBackground = "mainSectionBackground",
  homeCloudsBackground = "homeCloudsBackground",
  homeBackground = "homeBackground",
  transparentRobotBackground = "transparentRobotBackground",
  mobileAppsPhone = "mobileAppsPhone",
  mobileAppsBackground = "mobileAppsBackground",
  webSolutionsBackground = "webSolutionsBackground",
  webSolutionsLaptop = "webSolutionsLaptop",
  webSolutionsTablet = "webSolutionsTablet",
  animationStoryVision = "animationStoryVision",
  animationBackground = "animationBackground",
  industrySoftwarePC1 = "industrySoftwarePC1",
  industrySoftwarePC2 = "industrySoftwarePC2",
  industrySoftwareBackground = "industrySoftwareBackground",
  userExperienceDesignBackground = "userExperienceDesignBackground",
  userExperienceDesignPeople = "userExperienceDesignPeople",
  userExperienceDesignRobotBackground = "userExperienceDesignRobotBackground",
  brandingBackground = "brandingBackground",
  digitalMarketingBackground = "digitalMarketingBackground",
  australia = "australia",
}

export const images: ImageItem = {
  [ImageKeys.mainSectionBackground]: {
    mobile: MainSectionBackgroundMobile,
    tablet: MainSectionBackgroundTablet,
    desktop: MainSectionBackgroundDesktop,
    thumbnail: MainSectionBackgroundThumbnail,
  },
  [ImageKeys.homeCloudsBackground]: {
    mobile: HomeCloudsBackgroundMobile,
    tablet: HomeCloudsBackgroundTablet,
    desktop: HomeCloudsBackgroundDesktop,
    thumbnail: HomeCloudsBackgroundThumbnail,
  },
  [ImageKeys.homeBackground]: {
    mobile: HomeBackgroundMobile,
    tablet: HomeBackgroundTablet,
    desktop: HomeBackgroundDesktop,
    thumbnail: HomeBackgroundThumbnail,
  },
  [ImageKeys.transparentRobotBackground]: {
    mobile: TransparentRobotBackgroundMobile,
    tablet: TransparentRobotBackgroundTablet,
    desktop: TransparentRobotBackgroundDesktop,
    thumbnail: TransparentRobotBackgroundThumbnail,
  },
  [ImageKeys.mobileAppsPhone]: {
    mobile: MobileAppsPhoneMobile,
    tablet: MobileAppsPhoneTablet,
    desktop: MobileAppsPhoneDesktop,
  },
  [ImageKeys.mobileAppsBackground]: {
    mobile: MobileAppsBackgroundMobile,
    tablet: MobileAppsBackgroundTablet,
    desktop: MobileAppsBackgroundDesktop,
    thumbnail: MobileAppsBackgroundThumbnail,
  },
  [ImageKeys.webSolutionsBackground]: {
    mobile: WebSolutionsBackgroundMobile,
    tablet: WebSolutionsBackgroundTablet,
    desktop: WebSolutionsBackgroundDesktop,
    thumbnail: WebSolutionsBackgroundThumbnail,
  },
  [ImageKeys.webSolutionsLaptop]: {
    mobile: WebSolutionsLaptopMobile,
    tablet: WebSolutionsLaptopTablet,
    desktop: WebSolutionsLaptopDesktop,
  },
  [ImageKeys.webSolutionsTablet]: {
    mobile: WebSolutionsTabletMobile,
    tablet: WebSolutionsTabletTablet,
    desktop: WebSolutionsTabletDesktop,
  },
  [ImageKeys.animationStoryVision]: {
    mobile: AnimationStoryVisionMobile,
    tablet: AnimationStoryVisionTablet,
    desktop: AnimationStoryVisionDesktop,
    thumbnail: AnimationStoryVisionThumbnail,
  },
  [ImageKeys.animationBackground]: {
    mobile: AnimationBackgroundMobile,
    tablet: AnimationBackgroundTablet,
    desktop: AnimationBackgroundDesktop,
  },
  [ImageKeys.industrySoftwarePC1]: {
    mobile: IndustrySoftwarePC1Mobile,
    tablet: IndustrySoftwarePC1Tablet,
    desktop: IndustrySoftwarePC1Desktop,
  },
  [ImageKeys.industrySoftwarePC2]: {
    mobile: IndustrySoftwarePC2Mobile,
    tablet: IndustrySoftwarePC2Tablet,
    desktop: IndustrySoftwarePC2Desktop,
  },
  [ImageKeys.industrySoftwareBackground]: {
    mobile: IndustrySoftwareBackgroundMobile,
    tablet: IndustrySoftwareBackgroundTablet,
    desktop: IndustrySoftwareBackgroundDesktop,
    thumbnail: IndustrySoftwareBackgroundThumbnail,
  },
  [ImageKeys.userExperienceDesignBackground]: {
    mobile: UserExperienceDesignBackgroundMobile,
    tablet: UserExperienceDesignBackgroundTablet,
    desktop: UserExperienceDesignBackgroundDesktop,
  },
  [ImageKeys.userExperienceDesignPeople]: {
    mobile: UserExperienceDesignPeopleMobile,
    tablet: UserExperienceDesignPeopleTablet,
    desktop: UserExperienceDesignPeopleDesktop,
  },
  [ImageKeys.userExperienceDesignRobotBackground]: {
    mobile: UserExperienceDesignRobotBackgroundDesktop,
    tablet: UserExperienceDesignRobotBackgroundTablet,
    desktop: UserExperienceDesignRobotBackgroundDesktop,
  },
  [ImageKeys.brandingBackground]: {
    mobile: BrandingBackgroundMobile,
    tablet: BrandingBackgroundTablet,
    desktop: BrandingBackgroundDesktop,
    thumbnail: BrandingBackgroundThumbnail,
  },
  [ImageKeys.digitalMarketingBackground]: {
    mobile: DigitalMarketingBackgroundMobile,
    tablet: DigitalMarketingBackgroundTablet,
    desktop: DigitalMarketingBackgroundDesktop,
    thumbnail: DigitalMarketingBackgroundThumbnail,
  },
  [ImageKeys.australia]: {
    mobile: AustraliaMobile,
    desktop: AustraliaDesktop,
  },
};
