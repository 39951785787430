import React, { useCallback, useEffect, useState } from "react";
import { CheckboxProps } from "../../interfaces/components/checkbox";
import styles from "./checkbox.module.scss";
import { HiCheck } from "react-icons/hi";
const Checkbox: React.FC<CheckboxProps> = ({
  label,
  boldLabel,
  onValueChange,
  value,
  lightMode = false,
}) => {
  /**
   * Represents the state of the checkbox.
   */
  const [isChecked, setIsChecked] = useState<boolean>(value);

  /**
   * Toggles the checkbox value.
   */
  const toggleValue = useCallback(() => {
    const temp = !isChecked;
    setIsChecked(temp);
    if (onValueChange) onValueChange(temp);
  }, [isChecked, onValueChange]);

  /**
   *  Update the checkbox state when the value prop changes
   */
  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <div
      className={`${styles["checkbox-container"]} d-flex align-items-center`}
      onClick={toggleValue}
    >
      <div
        className={`d-flex align-items-center justify-content-center ${styles["box-icon"]} ${lightMode ? styles["light-theme"] : ""}`}
      >
        <HiCheck style={{ opacity: isChecked ? 1 : 0 }} />
      </div>
      <label>
        {label}
        <b>{boldLabel}</b>
      </label>
    </div>
  );
};

export default Checkbox;
