const industriesWeServeList = [
  {
    title: "industrialSoftware.automotiveTitle",
    description: "industrialSoftware.automotiveDescription",
  },
  {
    title: "industrialSoftware.medicalTitle",
    description: "industrialSoftware.medicalDescription",
  },
  {
    title: "industrialSoftware.retailTitle",
    description: "industrialSoftware.retailDescription",
  },
  {
    title: "industrialSoftware.manufacturingTitle",
    description: "industrialSoftware.manufacturingDescription",
  },
  {
    title: "industrialSoftware.agricultureTitle",
    description: "industrialSoftware.agricultureDescription",
  },
  {
    title: "industrialSoftware.educationTitle",
    description: "industrialSoftware.educationDescription",
  },
  {
    title: "industrialSoftware.pharmaTitle",
    description: "industrialSoftware.pharmaDescription",
  },
  {
    title: "industrialSoftware.marketingTitle",
    description: "industrialSoftware.marketingDescription",
  },
  {
    title: "industrialSoftware.logisticsTitle",
    description: "industrialSoftware.logisticsDescription",
  },
  {
    title: "industrialSoftware.bankingTitle",
    description: "industrialSoftware.bankingDescription",
  },
  {
    title: "industrialSoftware.advertisingTitle",
    description: "industrialSoftware.advertisingDescription",
  },
  {
    title: "industrialSoftware.financeTitle",
    description: "industrialSoftware.financeDescription",
  },
];

export default industriesWeServeList;
