import React from "react";
import { OurProcessItemProps } from "../../interfaces/components/ourProcessItem";
import styles from "./ourProcessItem.module.scss";
const OurProcessItem: React.FC<OurProcessItemProps> = ({
  title,
  description,
  index,
  className = "",
  descriptionClass = "",
  homeType = true,
}) => {
  return (
    <div
      className={`${styles["ourProcessItem-container"]} ${homeType ? styles["home"] : ""} ${className}`}
    >
      <p className={`${styles["description"]} ${descriptionClass}`}>
        {description}
      </p>
      <p className={styles["title"]}>{title}</p>
      <p className={`${styles["number"]} ${styles["num-" + index]}`}>{index}</p>
    </div>
  );
};

export default OurProcessItem;
