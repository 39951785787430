import React from "react";
import styles from "./caseListSection.module.scss";
import { CaseListSectionProps } from "../../../../../interfaces/screens/caseStudyList/caseListSection";
import useCaseStudy from "../../useCaseStudy";
import CaseStudyListItem from "../../../../../components/caseStudyListItem";
import useInfiniteScroll from "../../../../../shared/hooks/useInfiniteScroll";
import CaseItemSkeleton from "../caseItemSkeleton";

const CaseListSection: React.FC<CaseListSectionProps> = ({
  marginTop,
  categoryIds,
}) => {
  const { isLoading, isError, caseStudy, hasMore, fetchMore } =
    useCaseStudy(categoryIds);
  const [sentinelRef, isFetching] = useInfiniteScroll(fetchMoreData);

  function fetchMoreData() {
    if (hasMore && !isLoading) {
      fetchMore();
    }
  }

  return (
    <section
      style={marginTop ? { marginTop } : {}}
      className={`row justify-content-start ${styles["caseListSection-container"]}`}
    >
      {caseStudy.map((item) => (
        <CaseStudyListItem
          key={item.id}
          className={styles["case-item"]}
          images={item.categories}
          description={item.tagline}
          title={item.title}
          slug={item.slug}
          logo={item.logo}
          headerId={+item.header_category_id}
        />
      ))}
      <div ref={sentinelRef}></div>
      {isLoading &&
        new Array(5).fill(1).map((_, idx) => (
          <div key={idx} className={"col-lg-4 col-md-6 col-sm-12 p-0"}>
            <CaseItemSkeleton />
          </div>
        ))}
    </section>
  );
};

export default CaseListSection;
