import { DigitalMarketingItem } from "../../interfaces/shared/static/digitalMarketing";

const digitalMarketingList: DigitalMarketingItem[] = [
  {
    label: "digitalMarketingWeCover",
    list: [
      "searchEngineOptimization",
      "searchEngineMarketing",
      "socialMediaMarketing",
      "emailMarketing",
      "inboundContent",
    ],
  },
  {
    label: "platformsWeCover",
    list: [
      "googleSearchConsole",
      "bingAds",
      "shopify",
      "activeCampaign",
      "mailChimp",
      "blogger",
      "googleBusiness",
      "googleAds",
    ],
  },
  {
    label: "platformWeCover",
    list: [
      "facebook",
      "instagram",
      "linkedin",
      "x",
      "youtube",
      "pinterest",
      "tiktok",
      "snapchat",
    ],
  },
];

export default digitalMarketingList;
