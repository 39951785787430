import React, { useEffect, useState } from "react";
import Welcome from "./welcome";
import Brief from "./brief";
import Results from "./results";
import SimilarProjects from "./similarProjects";
import BottomSection from "../../../../shared/components/bottomSection";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import styles from "./caseStudyDetails.module.scss";
import { useParams } from "react-router-dom";
import NotFound from "../../notFound";
import CaseStudyDetailsLoader from "./caseStudyDetailsLoader";
import {
  extractContent,
  getCaseStudyTypeById,
} from "../../../../shared/utils/helper";
import useCaseStudyDetails from "../useCaseStudyDetails";
import { CategoryTypes } from "../../../../interfaces/components/caseStudyItem";
import LeftDynamicSection from "./leftDynamicSection";
import RightDynamicSection from "./rightDynamicSection";
import { CategoryIds } from "../../../../shared/utils/enums";

const CaseStudyDetails = () => {
  const { slug } = useParams();
  const { isLoading, caseStudy, similarCaseStudies, isError } =
    useCaseStudyDetails(slug || "");
  const [type, setType] = useState<CategoryTypes | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setLoaded(true), 1000); // Gives a smoother transition
    }
    return () => setLoaded(false);
  }, [isLoading]);

  useEffect(() => {
    if (caseStudy.categories?.length) {
      setType(getCaseStudyTypeById(+caseStudy.categories[0].category_id));
    }
  }, [caseStudy]);

  if ((isLoading || !loaded || !type) && !isError) {
    return <CaseStudyDetailsLoader loaded={isLoading} />;
  }

  if (isError) {
    return <NotFound />;
  }
  return (
    <div
      className={`${styles["fade-in"]} ${styles["caseStudyDetails-container"]}`}
    >
      {caseStudy &&
        generateMeta({
          title: `Case Study - ${caseStudy.title}` || metaData.caseStudy.title,
          description:
            extractContent(caseStudy.tagline) || metaData.caseStudy.description,
          image: caseStudy.categories[0].cover.url.mobile,
        })}
      <div className={styles["caseStudyDetails-container"]}>
        <Welcome
          title={caseStudy.title}
          categoryId={+caseStudy.categories[0].category_id}
          images={caseStudy.categories}
          type={type}
          headerId={+caseStudy.header_category_id}
        />
        <div
          className={`relative main-content ${styles["animation-main-content"]}`}
        >
          <Brief
            brief={caseStudy.brief}
            headerId={+caseStudy.header_category_id}
            tagline={caseStudy.tagline}
          />
          {caseStudy.sections.map((item, idx) => {
            return idx % 2 !== 0 ? (
              <LeftDynamicSection
                key={item.id}
                text={item.text}
                title={item.title}
                images={item.media}
                type={getCaseStudyTypeById(+item.category_id)}
                isMobile={+item.category_id === CategoryIds.mobile}
              />
            ) : (
              <RightDynamicSection
                key={item.id}
                text={item.text}
                title={item.title}
                images={item.media}
                type={getCaseStudyTypeById(+item.category_id)}
                isMobile={+item.category_id === CategoryIds.mobile}
              />
            );
          })}
          <Results
            results={caseStudy.results}
            images={caseStudy.footer_media}
            type={getCaseStudyTypeById(+caseStudy.footer_category_id)}
            isMobile={+caseStudy.footer_category_id === CategoryIds.mobile}
          />
          {similarCaseStudies.length && (
            <SimilarProjects similar={similarCaseStudies} />
          )}
          <BottomSection />
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetails;
