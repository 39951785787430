import React from "react";
import useResponsiveImage from "../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../assets/images";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import welcomeImage from "../../../../assets/images/shared/branding/welcome.svg";
import styles from "./branding.module.scss";
import WeDesignForBrands from "./weDesignForBrands";
import ThingsWeDo from "./thingsWeDo";
import borderImage from "../../../../assets/images/shared/branding/border.webp";
import BottomSection from "../../../../shared/components/bottomSection";
import LazyLoadImage from "../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../shared/utils/imageHelper";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";
const Branding = () => {
  const background = useResponsiveImage(ImageKeys.brandingBackground);

  return (
    <div className={styles["branding-container"]}>
      {generateMeta({
        title: metaData.branding.title,
        description: metaData.branding.description,
      })}
      <MainSection
        gradientBackground
        label={"branding.title"}
        image={welcomeImage}
        labelClass={styles["custom-label"]}
        descriptionClass={styles["custom-description"]}
        bottomContainerClass={styles["custom-bottom-container"]}
        imageClass={styles["custom-image"]}
        description={"branding.description"}
        showConsultationButton
      />
      <div className={"relative"}>
        <LazyLoadImage
          className={"background-image"}
          src={background}
          placeholderSrc={getThumbnail(ImageKeys.brandingBackground)}
          alt={"We can do"}
        />
        <img
          className={styles["border-image"]}
          src={borderImage}
          width={"100%"}
          height={"auto"}
          alt={"we can do"}
        />
        <WeDesignForBrands />
        <ThingsWeDo />
      </div>
      <div className={`case-bottom-container ${styles["case-study"]}`}>
        <CaseStudyCarousel />
      </div>
      <BottomSection className={styles["bottom-section"]} />
    </div>
  );
};

export default Branding;
