import React from "react";
import { useTranslation } from "react-i18next";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import styles from "./ourTechnicalExpertise.module.scss";
import ourTechnicalExpertiseList from "../../../../../shared/static/ourTechnicalExpertise";
import { letsTalk } from "../../../../../shared/services/contactService";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";
const OurTechnicalExpertise = () => {
  const { t } = useTranslation();
  const background = useResponsiveImage(ImageKeys.webSolutionsBackground);

  return (
    <section
      id={"technical-expertise"}
      className={`relative ${styles["ourTechnicalExpertise-container"]}`}
    >
      <LazyLoadImage
        className={"background-image"}
        src={background}
        alt={"technical expertise"}
        placeholderSrc={getThumbnail(ImageKeys.webSolutionsBackground)}
      />
      <div className={`main-content ${styles["page-content"]}`}>
        <h2>{t("webSolutions.technicalExpert")}</h2>
        {ourTechnicalExpertiseList.map((item, idx) => (
          <div
            key={idx}
            className={`${styles["technical-item"]} d-flex align-items-center justify-content-center`}
          >
            <img src={item.icon} width={120} height={"auto"} alt={item.title} />
            <div>
              <h3>{t(item.title)}</h3>
              <p>{t(item.description)}</p>
            </div>
          </div>
        ))}
        <p className={styles["note"]}>{t("webSolutions.note")}</p>
        <div className={"d-flex justify-content-center"}>
          <p className={`letsTalk ${styles["custom-letsTalk"]}`}>
            {t("webSolutions.notOnline")}{" "}
            <a href={"#contact"} onClick={(event) => letsTalk(event)}>
              {t("app.letsTalk")}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurTechnicalExpertise;
