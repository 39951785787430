import environment from "../environment/environment";

const baseUrl = environment.baseUrl;

const api = {
  blogs: `${baseUrl}blogs`,
  blogDetails: `${baseUrl}blogs/details/`,
  contact: `${baseUrl}consultations/add`,
  caseStudyCategories: `${baseUrl}guest/categories`,
  caseStudyList: `${baseUrl}guest/projects`,
};

export default api;
