import React from "react";
import Skeleton from "../../../../components/skeleton";
import styles from "./insightsListLoader.module.scss";
import { DeviceTypes } from "../../../../shared/utils/enums";
import useDeviceType from "../../../../shared/hooks/deviceType";

const InsightsListLoader: React.FC = () => {
  const deviceType = useDeviceType();
  const height = {
    [DeviceTypes.wideDesktop]: "516px",
    [DeviceTypes.desktop]: "447px",
    [DeviceTypes.tablet]: "460px",
    [DeviceTypes.mobile]: "450px",
  };
  return (
    <div className={styles["insightsListLoader-container"]}>
      {new Array(6).fill("").map((item, idx) => (
        <div key={idx}>
          <Skeleton
            height={height[deviceType]}
            borderRadius={"20px"}
            marginTop={"6%"}
          />
        </div>
      ))}
    </div>
  );
};

export default InsightsListLoader;
