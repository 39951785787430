import React from "react";
import { useTranslation } from "react-i18next";
import { CaseStudySimilarProps } from "../../../../../interfaces/screens/caseStudyDetails";
import Carousel from "../../../../../shared/components/carousel";
import styles from "./similarProjects.module.scss";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";
import { Project } from "../../../../../interfaces/api/caseStudy";
import CaseStudyListItem from "../../../../../components/caseStudyListItem";
import { getCaseStudyTypeById } from "../../../../../shared/utils/helper";

const SimilarProjects: React.FC<CaseStudySimilarProps> = ({ similar }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const renderItem = ({ item }: { item: Project }) => {
    return (
      <div className={styles["carousel-item"]}>
        <CaseStudyListItem
          col={"col-12"}
          key={item.id}
          images={item.categories}
          description={item.tagline}
          className={styles["case-item"]}
          title={item.title}
          slug={item.slug}
          headerId={+item.header_category_id}
          type={getCaseStudyTypeById(+item.categories[0].category_id)}
        />
      </div>
    );
  };

  return (
    <section id={"similar-projects"} className={styles["similar-container"]}>
      <h2 className={styles["title"]}>{t("caseStudyDetails.similar")}</h2>
      {isDesktop ? (
        <div className={`row justify-content-center`}>
          {similar.map((item) => (
            <React.Fragment key={item.id}>
              {renderItem({ item })}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <Carousel
          className={styles["carousel-container"]}
          blueDots
          showDots
          list={similar.length === 1 ? [...similar, ...similar] : similar}
          slidesToShow={1}
          renderItem={renderItem}
          loop={!isDesktop}
        />
      )}
    </section>
  );
};

export default SimilarProjects;
