import React from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import pcImage from "../../../../assets/images/shared/industrialSoftware/welcome.webp";
import styles from "./industrySpecificSoftware.module.scss";
import StreamlinedData from "./streamlinedData";
import IndustriesWeServe from "./industriesWeServe";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";
const IndustrySpecificSoftware = () => {
  return (
    <div className={styles["industrySpecificSoftware-container"]}>
      {generateMeta({
        title: metaData.industrySpecificSoftware.title,
        description: metaData.industrySpecificSoftware.description,
      })}
      <MainSection
        gradientBackground
        label={"industrialSoftware.title"}
        labelClass={styles["welcome-title"]}
        descriptionClass={styles["welcome-description"]}
        imageClass={styles["welcome-image"]}
        image={pcImage}
        description={"industrialSoftware.description"}
        showConsultationButton
        smallLabelStyle
      />
      <StreamlinedData />
      <IndustriesWeServe />
      <div className={"case-bottom-container"}>
        <CaseStudyCarousel />
      </div>
      <BottomSection />
    </div>
  );
};

export default IndustrySpecificSoftware;
