import React from "react";
import styles from "./caseStudyItem.module.scss";
import { CaseStudyItemProps } from "../../interfaces/components/caseStudyItem";
import { useTranslation } from "react-i18next";
import { truncateText } from "../../shared/utils/helper";
import useDeviceType from "../../shared/hooks/deviceType";
import useIsDesktop from "../../shared/hooks/isDesktop";
import { NavLink } from "react-router-dom";
import CaseTinyImage from "../caseTinyImage";
import bigMockupConfig from "../../shared/static/bigMockupConfig";

const CaseStudyItem: React.FC<CaseStudyItemProps> = ({
  title,
  description,
  images,
  className,
  badge = "",
  slug,
  smallVersion = false,
  headerId,
                                                       logo,
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  return (
      <NavLink
          draggable={false}
          to={"/case-study/" + slug}
          className={`${styles["caseStudyItem-container"]} row align-items-center relative p-0 ${className} ${smallVersion ? styles["small-version"] : ""}`}
      >
        <div
            className={`${styles["text-container"]} col-6`}
        >
          <h2>{title}</h2>
          <p>{truncateText(description, isDesktop ? 43 : 43)}</p>
          <button>{t("home.explore")}</button>
        </div>
        <div className={'col-6 p-0'}>
          <CaseTinyImage images={images} logo={logo} headerId={headerId} config={bigMockupConfig}/>
        </div>
      </NavLink>
  );
};

export default CaseStudyItem;
