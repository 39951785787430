import { useEffect, useState, useRef } from "react";

type CallbackFunction = () => void;

const useInfiniteScroll = (
  callback: CallbackFunction,
): [React.RefObject<HTMLDivElement>, boolean] => {
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsFetching(true);
      }
    });

    const currentObserver = observer.current;
    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      currentObserver.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        currentObserver.unobserve(currentSentinel);
      }
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback();
    setIsFetching(false);
  }, [isFetching, callback]);

  return [sentinelRef, isFetching];
};

export default useInfiniteScroll;
