import { useState, useEffect } from "react";
import { getCategories } from "../actions";
import {
  CaseStudyCategoriesHookResponse,
  CaseStudyCategory,
} from "../../../../interfaces/api/caseStudy";
import { allCategoryId } from "../../../../shared/static/caseStudy";

const useCaseStudyDetails = (): CaseStudyCategoriesHookResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<CaseStudyCategory[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);
        const res = await getCategories();
        if (res) {
          setCategories([
            {
              id: allCategoryId,
              name: "All",
              created_at: "",
              updated_at: "",
            },
            ...res,
          ]);
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, []);

  return { isLoading, categories, isError };
};

export default useCaseStudyDetails;
