import React from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import welcome from "../../../../assets/images/shared/digitalMarketing/welcome.svg";
import styles from "./digitalMarketing.module.scss";
import ConversionNavigator from "./conversionNavigator";
import DigitalServices from "./digitalServices";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";

const DigitalMarketing = () => {
  return (
    <div className={styles["digitalMarketing-container"]}>
      {generateMeta({
        title: metaData.digitalMarketing.title,
        description: metaData.digitalMarketing.description,
      })}
      <MainSection
        gradientBackground
        label={"digitalMarketing.title"}
        image={welcome}
        labelClass={styles["custom-welcome-label"]}
        descriptionClass={styles["custom-welcome-description"]}
        imageClass={styles["custom-welcome-image"]}
        description={"digitalMarketing.description"}
        bottomContainerClass={styles["custom-bottom-container"]}
        showConsultationButton
      />
      <ConversionNavigator />
      <DigitalServices />
      <div className={`case-bottom-container ${styles["case-study"]}`}>
        <CaseStudyCarousel />
      </div>
      <BottomSection className={styles["bottom-section"]} />
    </div>
  );
};

export default DigitalMarketing;
