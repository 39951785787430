import React, { useCallback, useEffect, useState } from "react";
import ResponsiveMockup from "../responsiveMockup";
import { CaseTinyImageProps } from "../../interfaces/components/caseTinyImage";
import { getCaseStudyTypeById } from "../../shared/utils/helper";
import mockupConfigs from "../../shared/static/mockupConfig";
import styles from "./caseTinyImage.module.scss";
import { CategoryIds } from "../../shared/utils/enums";
import { Category, Image } from "../../interfaces/api/caseStudy";

const CaseTinyImage: React.FC<CaseTinyImageProps> = ({
  images,
  logo,
  headerId,
    config = mockupConfigs
}) => {
  const [mobileItem, setMobileItem] = useState<Category | undefined>(undefined);
  const getConfigByCategoryId = useCallback(
    (categoryId: number) => {
      const type = getCaseStudyTypeById(categoryId);
      return type !== undefined ? config[type] : {};
    },
    [images],
  );

  useEffect(() => {
    const mobile = images.find(
      (item) => +item.category_id === CategoryIds.mobile,
    );
    setMobileItem(mobile);
  }, []);

  const getHeaderImage = useCallback(() => {
    return images.find((item) => +item.category_id === headerId) || images[0];
  }, [images]);

  return (
    <div className={`${styles["tinyImage-container"]} relative`}>
      <ResponsiveMockup
        className="medium-canvas"
        imageUrl={getHeaderImage().cover.url.desktop}
        {...(getConfigByCategoryId(+getHeaderImage().category_id) as any)}
      />
      {mobileItem && images.length > 1 && (
        <div className={styles["mobile-item"]}>
          <ResponsiveMockup
            className="medium-canvas"
            imageUrl={mobileItem.cover.url.desktop}
            {...(getConfigByCategoryId(+mobileItem.category_id) as any)}
          />
        </div>
      )}
      {((!mobileItem && images.length > 1) || images.length > 2) &&
        !!logo?.url && (
          <div className={styles["logo-container"]}>
            <img
              src={logo?.url.mobile}
              alt={logo?.type}
              className={styles["logo"]}
            />
          </div>
        )}
    </div>
  );
};

export default CaseTinyImage;
