import React from "react";
import { CaseStudyDetailsProps } from "../../../../../interfaces/screens/caseStudyDetails";
import styles from "./leftDynamicSection.module.scss";
import ResponsiveMockup from "../../../../../components/responsiveMockup";
import detailsMockupConfigs from "../../../../../shared/static/detailsMockupConfig";
import { getCaseStudyPlaceholder } from "../../../../../shared/static/caseStudy";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";

const LeftDynamicSection: React.FC<CaseStudyDetailsProps> = ({
  text,
  images,
  type,
  isMobile,
  title,
}) => {
  const config = type !== undefined ? detailsMockupConfigs[type] : {};
  const isDesktop = useIsDesktop();

  return (
    <section
      id={"our-process"}
      className={`row ${styles["ourProcess-container"]} relative align-items-center`}
    >
      <div
        className={`${isMobile && images.length > 1 ? "col-lg-4" : "col-lg-6"} col-md-12 col-sm-12 ${styles["text-container"]} ${isMobile && styles["mobile-text-container"]} `}
      >
        <h2>{title}</h2>
        <p className={"mb-0"}>{text}</p>
        <svg
          className={styles.svgBottomImage}
          width="276"
          height="3"
          viewBox="0 0 276 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="276"
            y1="1.5"
            y2="1.5"
            stroke="url(#paint0_linear_1198_8659)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1198_8659"
              x1="276"
              y1="0"
              x2="275.965"
              y2="-3.09305"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FFE0" />
              <stop offset="1" stopColor="#0057FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className={`${isMobile && images.length > 1 ? "col-lg-8" : "col-lg-6"} col-md-12 col-sm-12 relative`}
      >
        {images.length > 0 && (
          <svg
            className={styles.svgTopImage}
            style={
              isMobile
                ? {
                    top: images.length > 1 ? "-34%" : "-18%",
                    transform: `translate(${(isDesktop || images.length > 0) ? 54 : 50}%)`,
                  }
                : {}
            }
            viewBox="0 0 516 202"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.00003 202L2.00007 37C2.00007 17.67 17.6701 2 37.0001 2L559 1.99996"
              stroke="url(#paint0_radial_1198_8671)"
              strokeWidth="3"
            />
            <defs>
              <radialGradient
                id="paint0_radial_1198_8671"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(-15.7664 -5.75002) rotate(19.9054) scale(655.716 649.328)"
              >
                <stop stopColor="#00FFE0" />
                <stop offset="1" stopColor="#0057FF" />
              </radialGradient>
            </defs>
          </svg>
        )}
        <div className={`d-flex justify-content-center`}>
          {new Array(images.length).fill(1).map((_, idx) => (
            <div
              style={{
                left:
                  idx === 1
                    ? "10%"
                    : idx === 2
                      ? "6%"
                      : images.length !== 1
                        ? "10%"
                        : "14%",
              }}
              key={idx}
              className={`${styles[isMobile ? (images.length > 1 ? "mobile-main-image" : "one-mobile-image") : "main-image"]} relative`}
            >
              <ResponsiveMockup
                imageUrl={
                  images[idx]?.url?.desktop || getCaseStudyPlaceholder(type)
                }
                {...(config as any)}
                {...(type === "branding" && { blur: 20 })}
                {...(type === "animation" && {
                  isVideo: true,
                  videoUrl: images[idx].url,
                })}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LeftDynamicSection;
