import React from "react";
import { CaseStudyDetailsProps } from "../../../../../interfaces/screens/caseStudyDetails";
import styles from "./rightDynamicSection.module.scss";
import ResponsiveMockup from "../../../../../components/responsiveMockup";
import detailsMockupConfigs from "../../../../../shared/static/detailsMockupConfig";
import { getCaseStudyPlaceholder } from "../../../../../shared/static/caseStudy";

const RightDynamicSection: React.FC<CaseStudyDetailsProps> = ({
  text,
  title,
  images,
  type,
  isMobile,
}) => {
  const config = type !== undefined ? detailsMockupConfigs[type] : {};

  return (
    <section
      id={"our-solutions"}
      className={`row ${styles["ourSolution-container"]} relative`}
    >
      <div
        className={`${isMobile && images.length > 1 ? "col-lg-8" : "col-lg-6"} col-md-12 col-sm-12 relative`}
      >
        <div className={`d-flex`}>
          {new Array(images.length).fill(1).map((_, idx) => (
            <div
              style={{ right: idx === 1 ? "4%" : idx === 2 ? "8%" : "0" }}
              key={idx}
              className={`${styles[isMobile ? (images.length > 1 ? "mobile-main-image" : "one-mobile-image") : "main-image"]} relative`}
            >
              <ResponsiveMockup
                imageUrl={
                  images[idx]?.url?.desktop || getCaseStudyPlaceholder(type)
                }
                {...(config as any)}
                {...(type === "branding" && { blur: 20 })}
                {...(type === "animation" && {
                  isVideo: true,
                  videoUrl: images[idx].url,
                })}
              />
            </div>
          ))}
        </div>
        {images.length > 0 && (
          <svg
            className={`${styles.svgUnderImage} ${isMobile && (images.length > 1 ? styles["mobile-svg-under-image"] : styles["one-mobile-svg-under-image"])}`}
            style={!["laptop", "mobile"].includes(type) ? { zIndex: 1 } : {}}
            width="519"
            height="201"
            viewBox="0 0 519 201"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M517 9.7389e-05L517 164C517 183.33 501.33 199 482 199L-40 199"
              stroke="url(#paint0_radial_1198_8667)"
              strokeWidth="3"
            />
            <defs>
              <radialGradient
                id="paint0_radial_1198_8667"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(534.766 206.711) rotate(-160.186) scale(655.337 646.455)"
              >
                <stop stopColor="#00FFE0" />
                <stop offset="1" stopColor="#0057FF" />
              </radialGradient>
            </defs>
          </svg>
        )}
      </div>
      <div
        className={`${isMobile && images.length > 1 ? "col-lg-4" : "col-lg-6"} col-md-12 col-sm-12 ${styles["text-container"]} ${isMobile && styles["mobile-text-container"]} `}
      >
        <h2 className={"mt-0"}>{title}</h2>
        <p>{text}</p>
        <svg
          className={styles.svgTopImage}
          width="276"
          height="3"
          viewBox="0 0 276 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="277"
            y1="1.5"
            x2="-1.31134e-07"
            y2="1.49998"
            stroke="url(#paint0_linear_1198_8662)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1198_8662"
              x1="2.91229e-06"
              y1="-2.84103e-06"
              x2="0.0362703"
              y2="3.32504"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FFE0" />
              <stop offset="1" stopColor="#0057FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default RightDynamicSection;
