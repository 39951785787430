import { OurProcessItemProps } from "../../interfaces/components/ourProcessItem";

const ourUxProcessList: OurProcessItemProps[] = [
  {
    title: "userExperienceDesign.discoverTitle",
    description: "userExperienceDesign.discoverDescription",
    index: 1,
  },
  {
    title: "userExperienceDesign.hypothesiseTitle",
    description: "userExperienceDesign.hypothesiseDescription",
    index: 2,
  },
  {
    title: "userExperienceDesign.designTitle",
    description: "userExperienceDesign.designDescription",
    index: 3,
  },
  {
    title: "userExperienceDesign.testTitle",
    description: "userExperienceDesign.testDescription",
    index: 4,
  },
];

export default ourUxProcessList;
