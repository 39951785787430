import React from "react";
import styles from "./streamlinedData.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import { letsTalk } from "../../../../../shared/services/contactService";
const StreamlinedData = () => {
  const { t } = useTranslation();
  const pc1 = useResponsiveImage(ImageKeys.industrySoftwarePC1);
  const pc2 = useResponsiveImage(ImageKeys.industrySoftwarePC2);
  return (
    <section
      id={"streamlined-data"}
      className={`${styles["streamlinedData-container"]}`}
    >
      <div className={`p-0 row align-items-start ${styles["pc1-container"]}`}>
        <LazyLoadImage
          className={"col-lg-6 col-md-12 col-sm-12 p-0"}
          src={pc1}
          width={"100%"}
          height={"auto"}
          alt={"mobile details section"}
        />
        <p className={"col-lg-6 col-md-12 col-sm-12"}>
          {t("industrialSoftware.streamlinedData1")}
        </p>
      </div>
      <div className={`p-0 row ${styles["pc2-container"]}`}>
        <p className={"col-lg-8 col-md-12 col-sm-12"}>
          {t("industrialSoftware.streamlinedData2")}
        </p>
        <LazyLoadImage
          className={"col-lg-4 col-md-12 col-sm-12"}
          src={pc2}
          width={"100%"}
          height={"auto"}
          alt={"mobile details section"}
        />
      </div>
      <p className={styles["note"]}>
        {t("industrialSoftware.streamlineDataNote")}
      </p>
      <div className={"col-12 d-flex justify-content-center"}>
        <p className={`letsTalk ${styles["custom-letsTalk"]}`}>
          {t("industrialSoftware.lookingForSolution")}{" "}
          <a href={"#contact"} onClick={(event) => letsTalk(event)}>
            {t("app.letsTalk")}
          </a>
        </p>
      </div>
    </section>
  );
};

export default StreamlinedData;
