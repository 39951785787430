import http from "../http";
import api from "../../config/api";
import {
  CaseStudyCategoriesResponse,
  CaseStudyProjectDetailsResponse,
  CaseStudyProjectResponse,
} from "../../interfaces/api/caseStudy";
import { allCategoryId } from "../../shared/static/caseStudy";

const caseStudy = {
  getCategories: (): Promise<CaseStudyCategoriesResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(`${api.caseStudyCategories}?all=1`)
        .then((res) => resolve(res as CaseStudyCategoriesResponse))
        .catch((e) => reject(e));
    }),
  getProjectsByCategory: (
    categories: string,
    page: number,
    perPage: number,
  ): Promise<CaseStudyProjectResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(
          `${api.caseStudyList}${categories !== allCategoryId.toString() ? `?category_ids=${categories}&page=${page}` : `?page=${page}`}`,
        )
        .then((res) => resolve(res as CaseStudyProjectResponse))
        .catch((e) => reject(e));
    }),
  getFeaturedProjects: (): Promise<CaseStudyProjectResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(`${api.caseStudyList}?featured=1&all=1`)
        .then((res) => resolve(res as CaseStudyProjectResponse))
        .catch((e) => reject(e));
    }),
  getCaseStudyDetails: (
    slug: string,
  ): Promise<CaseStudyProjectDetailsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(`${api.caseStudyList}/${slug}`)
        .then((res) => resolve(res as CaseStudyProjectDetailsResponse))
        .catch((e) => reject(e));
    }),
};

export default caseStudy;
