import React from "react";
import styles from "./brief.module.scss";
import { BriefProps } from "../../../../../interfaces/screens/caseStudyDetails";
import { useTranslation } from "react-i18next";
import { CategoryIds } from "../../../../../shared/utils/enums";

const Brief: React.FC<BriefProps> = ({ brief, tagline, headerId }) => {
  const { t } = useTranslation();

  return (
    <section
      id={"brief"}
      className={`${styles["brief-container"]} ${headerId !== CategoryIds.mobile ? styles["w-100"] : styles["w-50"]} relative caseStudy-padding`}
    >
      <p className={styles["tagline"]}>{tagline}</p>
      <h2>{t("caseStudyDetails.brief")}</h2>
      <p>{brief}</p>
    </section>
  );
};

export default Brief;
