import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./caseStudyCarousel.module.scss";
import Carousel from "../carousel";
import { NavLink } from "react-router-dom";
import useIsDesktop from "../../hooks/isDesktop";
import useFeaturedProjects from "../../hooks/featuredCaseStudy";
import { Project } from "../../../interfaces/api/caseStudy";
import Skeleton from "../../../components/skeleton";
import CaseStudyItem from "../../../components/caseStudyItem";

const CaseStudyCarousel: React.FC = () => {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const { isLoading, isError, featuredProjects } = useFeaturedProjects();

    const renderItem = ({ item }: { item: Project }) => {
        return (
            <div className={`${styles["carousel-item"]} ${featuredProjects.length === 1 ? styles["only-one"] : ""}`}>
                <CaseStudyItem
                    images={item.categories}
                    description={item.tagline}
                    title={item.title}
                    slug={item.slug}
                    headerId={+item.header_category_id}
                    className={"col-12"}
                    logo={item.logo}
                />
            </div>
        );
    };

    const renderSkeletonItem = ({ item }: { item: number }) => {
        return (
            <div className={styles["carousel-item"]}>
                <Skeleton
                    width={"100%"}
                    height={"625px"}
                    borderRadius={"14px"}
                    blurLevel={50}
                />
            </div>
        );
    };

    const isSSR = typeof window === "undefined";
    const initialRender = isSSR || (isLoading && !featuredProjects.length);

    return (
        <section className={`${styles["caseStudy-container"]} ${(isError || (!featuredProjects.length && !isLoading)) ? "hidden" : ""}`} id={"caseStudy"}>
            <h2 className={"relative"}>
                <span className={styles["case"]}>{t("home.case")}</span>{" "}
                <span>{t("home.study")}</span>
            </h2>
            {initialRender ? (
                <Carousel
                    className={styles["carousel-container"]}
                    blueDots
                    list={[1, 2, 3]}
                    renderItem={renderSkeletonItem}
                    loop={!isDesktop}
                />
            ) : (
                featuredProjects.length && !isLoading ? (
                    <Carousel
                        className={styles["carousel-container"]}
                        blueDots
                        list={featuredProjects}
                        renderItem={renderItem}
                        loop={!isDesktop}
                    />
                ) : null
            )}
            <NavLink className={styles["viewAll"]} to={"/case-study"}>
                {t("app.viewAll")}
            </NavLink>
        </section>
    );
};

export default CaseStudyCarousel;
