import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/content/home";
import About from "../screens/content/about";
import NotFound from "../screens/content/notFound";
import ScrollToTop from "./scrollToTop";
import { isBrowserDefined } from "../shared/services/browserService";
import CaseStudyList from "../screens/content/caseStudy/caseStudyList";
import Insights from "../screens/content/insights/insightsList";
import InsightDetails from "../screens/content/insights/insightDetails";
import MobileApps from "../screens/content/services/mobileApps";
import IndustrySpecificSoftware from "../screens/content/services/industrySpecificSoftware";
import WebSolutions from "../screens/content/services/webSolutions";
import UserExperienceDesign from "../screens/content/services/userExperienceDesign";
import Branding from "../screens/content/services/branding";
import DigitalMarketing from "../screens/content/services/digitalMarketing";
import Animation from "../screens/content/services/animation";
import CustomLayout from "../screens/customLayout";
import "../translation/index";
import CaseStudyDetails from "../screens/content/caseStudy/caseStudyDetails";
import { screens } from "../config/routes";
import ContactUs from "../screens/content/contactUs";
import { store } from "../redux/store";
import { Provider } from "react-redux";
const MainRouter = () => {
  useEffect(() => {
    if (isBrowserDefined()) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  return (
    <Provider store={store}>
      <CustomLayout>
        {isBrowserDefined() && <ScrollToTop />}
        <Routes>
          <Route path={screens.home} element={<Home />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path={screens.caseStudy} element={<CaseStudyList />} />
          <Route
            path={`${screens.caseStudyDetails}:slug`}
            element={<CaseStudyDetails />}
          />
          <Route path={screens.insights} element={<Insights />} />
          <Route
            path={`${screens.insightDetails}:slug`}
            element={<InsightDetails />}
          />
          <Route path={screens.about} element={<About />} />
          <Route path={screens.mobileApps} element={<MobileApps />} />
          <Route
            path={screens.industrySpecificSoftware}
            element={<IndustrySpecificSoftware />}
          />
          <Route path={screens.webSolutions} element={<WebSolutions />} />
          <Route
            path={screens.userExperienceDesign}
            element={<UserExperienceDesign />}
          />
          <Route path={screens.branding} element={<Branding />} />
          <Route
            path={screens.digitalMarketing}
            element={<DigitalMarketing />}
          />
          <Route path={screens.animation} element={<Animation />} />
          <Route path={screens.contactUs} element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CustomLayout>
    </Provider>
  );
};

export default MainRouter;
