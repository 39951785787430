import React from "react";
import { InsightItemProps } from "../../interfaces/components/insightItem";
import styles from "./insightItem.module.scss";
import { NavLink } from "react-router-dom";
import LazyLoadImage from "../../shared/components/lazyLoadImage";

const InsightItem: React.FC<InsightItemProps> = ({
  title,
  description,
  image,
  navigateTo,
  placeholder,
  originalImage,
}) => {
  return (
    <NavLink to={navigateTo} className={styles["insightItem-container"]}>
      <LazyLoadImage
        src={image}
        alt={title}
        placeholderSrc={placeholder}
        originalImage={originalImage}
      />
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </NavLink>
  );
};

export default InsightItem;
