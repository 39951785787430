import React from "react";
import styles from "./welcome.module.scss";
import { CaseStudyWelcomeProps } from "../../../../../interfaces/screens/caseStudyDetails";
import { getCaseStudyBadge } from "../../../../../shared/static/caseStudy";
import { useTranslation } from "react-i18next";
import ResponsiveMockup from "../../../../../components/responsiveMockup";
import detailsMockupConfigs from "../../../../../shared/static/detailsMockupConfig";
import MultiCover from "../multiCover";
import { CategoryIds } from "../../../../../shared/utils/enums";

const Welcome: React.FC<CaseStudyWelcomeProps> = ({
  title,
  categoryId,
  images,
  type,
  headerId,
}) => {
  const { t } = useTranslation();
  const config = type !== undefined ? detailsMockupConfigs[type] : {};

  return (
    <section
      id={"welcome"}
      className={`${styles["welcome-container"]} caseStudy-padding relative`}
    >
      <div
        className={`${styles["text-content"]} ${images.length > 1 ? styles["various-container"] : ""} relative`}
      >
        <p>
          {t("caseStudy.title")} |{" "}
          {t(
            images.length > 1
              ? "caseStudy.various"
              : getCaseStudyBadge(categoryId),
          )}
        </p>
        <h1 className={styles["title"]}>{title}</h1>
      </div>
      <div
        className={`${styles["cover-image"]} ${images.length > 1 ? styles["multiple"] : ""} ${headerId === CategoryIds.mobile && styles["mobile-container"]} ${type === "branding" && styles["branding-container"]}`}
      >
        {images.length === 1 ? (
          <ResponsiveMockup
            imageUrl={images[0].cover.url.desktop}
            {...(config as any)}
          />
        ) : (
          <div>
            <MultiCover images={images} headerId={headerId} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Welcome;
