import React from "react";
import generateMeta from "../../../shared/services/metadataService";
import MainSection from "../../../shared/components/mainSection";
import metaData from "../../../config/metaData";
import OurServices from "./ourServices";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import styles from "./home.module.scss";
import Technologies from "./technologies";
import CustomProcess from "../../../components/customProcess";
import ourProcessList from "../../../shared/static/ourProcess";
import { useTranslation } from "react-i18next";
import BottomSection from "../../../shared/components/bottomSection";
import { getThumbnail } from "../../../shared/utils/imageHelper";
import LazyLoadImage from "../../../shared/components/lazyLoadImage";
import useDeviceType from "../../../shared/hooks/deviceType";
import { DeviceTypes } from "../../../shared/utils/enums";
import useScrollPosition from "../../../shared/hooks/scrollPosition";
import CaseStudyCarousel from "../../../shared/components/caseStudyCarousel";

const Home: React.FC = () => {
  const homeBackground = useResponsiveImage(ImageKeys.homeBackground);
  const deviceType = useDeviceType();
  const { t } = useTranslation();
  const { y } = useScrollPosition();

  return (
    <div className={styles["home-container"]}>
      {generateMeta({
        title: metaData.home.title,
        description: metaData.home.description,
      })}
      <MainSection
        label={"home.title"}
        labelClass={styles["mainSection-label"]}
        descriptionClass={styles["mainSection-description"]}
        description={"home.description"}
        showConsultationButton
      />
      <OurServices />
      <div className={"relative"}>
        <LazyLoadImage
          className={styles["home-background"]}
          src={homeBackground}
          isFirstImage
          placeholderSrc={getThumbnail(ImageKeys.homeBackground) || ""}
          alt={"home"}
          style={{
            transform: `translateY(-${y * (deviceType === DeviceTypes.mobile ? 0.2 : 0.32)}px)`,
          }}
        />
        <CustomProcess
          ourProcessList={ourProcessList}
          leftTitle={t("home.ourProcessTitlePartOne")}
          rightTitle={t("home.ourProcessTitlePartTwo")}
          sectionId={"our-process"}
        />
        <div className={styles["main-sections"]}>
          <Technologies />
          <CaseStudyCarousel />
        </div>
        <BottomSection />
      </div>
    </div>
  );
};

export default Home;
