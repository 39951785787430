import React from "react";
import styles from "./caseListLoader.module.scss";
import Skeleton from "../../../../../components/skeleton";
import CaseItemSkeleton from "../caseItemSkeleton";
import useDeviceType from "../../../../../shared/hooks/deviceType";
import { DeviceTypes } from "../../../../../shared/utils/enums";

const CaseListLoader = ({ withoutHeader = false }) => {
  const deviceType = useDeviceType();

  return (
    <div className={`${styles["caseListLoader-container"]}`}>
      {!withoutHeader && (
        <div className={`${styles["categories"]}  d-flex align-items-center`}>
          {new Array(5).fill(1).map((_, idx) => (
            <Skeleton
              key={idx}
              width={`${idx * 4 + (deviceType === DeviceTypes.mobile ? 20 : 5)}%`}
              height={"52px"}
              borderRadius={"10px"}
              blurLevel={50}
            />
          ))}
        </div>
      )}
      <div className={`row justify-content-start ${styles["list"]}`}>
        {new Array(5).fill(1).map((_, idx) => (
          <div key={idx} className={"col-lg-4 col-md-6 col-sm-12 p-0"}>
            <CaseItemSkeleton />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseListLoader;
