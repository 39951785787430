import React from "react";
import { useParams } from "react-router-dom";
import useBlogDetails from "../useBlogDetails";
import InsightDetailsLoader from "./insightDetailsLoader";
import InsightCarousel from "./insightCarousel";
import BlogContent from "./blogContent";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import { extractContent } from "../../../../shared/utils/helper";
import NotFound from "../../notFound";
import styles from "./insightDetails.module.scss";
import useIsDesktop from "../../../../shared/hooks/isDesktop";
import BottomSection from "../../../../shared/components/bottomSection";

const InsightDetails: React.FC = () => {
  const { slug } = useParams();
  const isDesktop = useIsDesktop();
  const { isLoading, blog, similarBlogs, isError } = useBlogDetails(slug || "");

  if (isLoading) {
    return <InsightDetailsLoader />;
  }

  if (isError) {
    return <NotFound />;
  }

  return (
    <div className={`${styles["insightDetails-container"]} main-section`}>
      {blog &&
        generateMeta({
          title: blog.title || metaData.insights.title,
          description:
            extractContent(blog.content) || metaData.insights.description,
          image: blog.image.thumbnail,
        })}
      <div className={`${styles["insight-content"]} relative`}>
        <BlogContent blog={blog} />
        {similarBlogs.length > 0 && <InsightCarousel list={similarBlogs} />}
      </div>
      <BottomSection />
    </div>
  );
};

export default InsightDetails;
