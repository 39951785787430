import mockup from "../../assets/images/mockups/mobile/full.svg";
import desktop from "../../assets/images/mockups/mac/half.webp";
import chrome from "../../assets/images/mockups/chrome/half.svg";
import branding from "../../assets/images/mockups/branding/half.svg";
import video from "../../assets/images/mockups/video/half.svg";
import play from "../../assets/images/mockups/video/play.svg";
import { MockupConfigs } from "../../interfaces/components/caseStudyItem";

const mockupConfigs: MockupConfigs = {
  mobile: {
    mockupUrl: mockup,
    x: 0.055,
    y: 0.045,
    width: 0.89,
    height: 0.91,
    borderRadius: 40,
    objectPosition: "center",
    overlayUrl: "",
    type: "mobile",
  },
  laptop: {
    mockupUrl: desktop,
    x: 0.34,
    y: 0.03,
    width: 0.9,
    height: 0.82,
    borderRadius: 0,
    ImageObjectFit: "cover",
    objectPosition: "left",
    mockupObjectPosition: "right",
  },
  web: {
    mockupUrl: chrome,
    x: 0.16,
    y: 0.09,
    width: 1,
    height: 0.9,
    borderRadius: { bl: 8 },
    ImageObjectFit: "cover",
    objectPosition: "left",
    mockupObjectPosition: "right",
    showImageOverMockup: true,
  },
  branding: {
    mockupUrl: branding,
    x: 0.11,
    y: 0.02,
    width: 1,
    height: 0.96,
    borderRadius: 8,
    ImageObjectFit: "cover",
    objectPosition: "left",
    mockupObjectPosition: "right",
    showImageOverMockup: true,
  },
  animation: {
    mockupUrl: video,
    x: 0.11,
    y: 0.02,
    width: 1,
    height: 0.96,
    borderRadius: 8,
    ImageObjectFit: "cover",
    objectPosition: "left",
    mockupObjectPosition: "right",
    showImageOverMockup: true,
    overlayUrl: play,
  },
};

export default mockupConfigs;
