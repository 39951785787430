import React from "react";
import { isBrowserDefined } from "./browserService";

/**
 * Scrolls the browser window to the contact section on the page.
 * @function
 * @returns {void}
 */
export const scrollToContact = (): void => {
  if (!isBrowserDefined()) return;
  const contactSection = document.getElementById("contact");
  if (!contactSection) return;
  const rect = contactSection.getBoundingClientRect();
  window.scrollTo({
    top: window.pageYOffset + rect.top - 100,
    behavior: "smooth",
  });
};

/**
 * Let's talk main function
 * @param event
 */
export const letsTalk = (event: React.MouseEvent<HTMLAnchorElement>): void => {
  event.preventDefault(); // Prevent the default anchor behavior
  scrollToContact(); // Scroll to the target section smoothly
};
