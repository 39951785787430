import React, { useEffect, useState } from "react";
import { LazyLoadImageProps } from "../../../interfaces/shared/components/lazyLoadImage";
import useIntersectionObserver from "../../hooks/intersectionObserver";
import useScrollPosition from "../../hooks/scrollPosition";

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  src,
  alt,
  placeholderSrc,
  className,
  style,
  isFirstImage = false,
  containerClassName = "",
  originalImage = "",
  minHeight = 100,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const imageRef = useIntersectionObserver(
    (isVisible) => {
      if (isVisible && !loaded) {
        preloadImage();
      }
    },
    {
      rootMargin: "0px",
      threshold: 0.1,
    },
  );
  const scrollPosition = useScrollPosition();

  const preloadImage = () => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  };

  useEffect(() => {
    if (scrollPosition.y > 20 && isFirstImage && !loaded) {
      preloadImage();
    }
  }, [scrollPosition, isFirstImage, loaded]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isFirstImage && !loaded) {
      timer = setTimeout(preloadImage, 4000);
    }

    return () => clearTimeout(timer);
  }, [isFirstImage, loaded]);

  return (
    <div
      className={containerClassName}
      ref={imageRef}
      style={{ minHeight: `${minHeight}px` }}
    >
      <img
        className={className}
        src={loaded ? src : placeholderSrc}
        alt={alt}
        style={style}
        onError={({ currentTarget }) => {
          if (originalImage && currentTarget.src !== originalImage) {
            currentTarget.onerror = null;
            currentTarget.src = originalImage;
          }
        }}
      />
    </div>
  );
};

export default LazyLoadImage;
