import React from "react";
import styles from "./weCanDo.module.scss";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import { useTranslation } from "react-i18next";
import { letsTalk } from "../../../../../shared/services/contactService";
import LazyLoadImage from "../../../../../shared/components/lazyLoadImage";
import { getThumbnail } from "../../../../../shared/utils/imageHelper";

const WeCanDo = () => {
  const background = useResponsiveImage(ImageKeys.animationBackground);
  const { t } = useTranslation();
  const weCanDoList = [
    "heroAnimation",
    "storytelling",
    "infographics",
    "descriptiveAnimation",
    "motionGraphics",
    "productAnimation",
    "brandAnimation",
    "dataVisualisation",
  ];
  const waysWeCanDoItList = [
    "3dAnimation",
    "2dAnimation",
    "simulation",
    "wireframe",
    "cgi",
  ];
  return (
    <section
      id={"things-we-can-do"}
      className={`${styles["thingsWeCanDo-container"]} relative`}
    >
      <LazyLoadImage
        className={"background-image"}
        src={background}
        placeholderSrc={getThumbnail(ImageKeys.animationBackground) || ""}
        alt={"We can do"}
        isFirstImage
      />
      <div className={`relative main-content ${styles["page-content"]}`}>
        <div className={styles["padding"]}>
          <h2>{t("animation.thingsWeCanDo")}</h2>
          <div
            className={`${styles["list-container"]} d-flex align-items-center`}
          >
            {weCanDoList.map((item) => (
              <p>{t(`animation.${item}`)}</p>
            ))}
          </div>
          <h2>{t("animation.waysWeCanDoIt")}</h2>
          <div
            className={`${styles["list-container"]} d-flex align-items-center`}
          >
            {waysWeCanDoItList.map((item) => (
              <p className={styles["ways-item"]}>{t(`animation.${item}`)}</p>
            ))}
          </div>
        </div>
        <div
          className={`${styles["letsTalk-container"]} col-12 d-flex justify-content-center`}
        >
          <p className={`letsTalk ${styles["custom-lets-talk"]}`}>
            {t("animation.haveStory")}{" "}
            <a href={"#contact"} onClick={(event) => letsTalk(event)}>
              {t("app.letsTalk")}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default WeCanDo;
