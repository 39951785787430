import { toast } from "react-toastify";
import { TypeOptions } from "react-toastify/dist/types";

const showGlobalToast = ({
  message,
  type,
  duration,
}: {
  message: string;
  type: TypeOptions;
  duration: number;
}) => {
  const toastId = Math.floor(Math.random() * 1000);
  toast(message, {
    position: "bottom-right",
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: type,
    toastId,
  });
  toast.play({ id: toastId });
};

const showSuccessToast = (message: string, duration: number = 5000) => {
  showGlobalToast({ message, type: "success", duration });
};

const showErrorToast = (
  message = "Something went wrong, please try again later!",
  duration: number = 5000,
) => {
  showGlobalToast({ message, type: "error", duration });
};

export const toastService = {
  showSuccessToast,
  showErrorToast,
};
