import React from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import mobileImage from "../../../../assets/images/shared/mobileApps/mobile.svg";
import PhoneSection from "./phoneSection";
import DevelopmentHub from "./developmentHub";
import styles from "./mobileApps.module.scss";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";

const MobileApps = () => {
  return (
    <div className={styles["mobileApps-container"]}>
      {generateMeta({
        title: metaData.mobileApps.title,
        description: metaData.mobileApps.description,
      })}
      <MainSection
        gradientBackground
        label={"mobileApps.title"}
        image={mobileImage}
        labelClass={styles["mainSection-custom-title"]}
        descriptionClass={styles["mainSection-custom-description"]}
        imageClass={styles["mainSection-custom-image"]}
        description={"mobileApps.description"}
        showConsultationButton
      />
      <PhoneSection />
      <DevelopmentHub />
      <div className={"case-bottom-container"}>
        <CaseStudyCarousel />
      </div>
      <BottomSection />
    </div>
  );
};

export default MobileApps;
