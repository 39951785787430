import c from "../../assets/images/shared/home/technologies/c.svg";
import cPlusPlus from "../../assets/images/shared/home/technologies/c++.svg";
import cSharp from "../../assets/images/shared/home/technologies/cSharp.svg";
import objC from "../../assets/images/shared/home/technologies/obj-c.svg";
import java from "../../assets/images/shared/home/technologies/java.svg";
import swift from "../../assets/images/shared/home/technologies/swift.svg";
import kotlin from "../../assets/images/shared/home/technologies/kotlin.svg";
import nativeImage from "../../assets/images/shared/home/technologies/native.svg";
import flutter from "../../assets/images/shared/home/technologies/flutter.svg";
import react from "../../assets/images/shared/home/technologies/react.svg";
import ionic from "../../assets/images/shared/home/technologies/ionic.svg";
import xamarin from "../../assets/images/shared/home/technologies/xamarin.svg";
import angular from "../../assets/images/shared/home/technologies/angular.svg";
import html from "../../assets/images/shared/home/technologies/html.svg";
import js from "../../assets/images/shared/home/technologies/js.svg";
import php from "../../assets/images/shared/home/technologies/php.svg";
import python from "../../assets/images/shared/home/technologies/python.svg";
import ruby from "../../assets/images/shared/home/technologies/ruby.svg";
import solidify from "../../assets/images/shared/home/technologies/solidify.svg";
import rust from "../../assets/images/shared/home/technologies/rust.svg";
import view from "../../assets/images/shared/home/technologies/view.svg";
import go from "../../assets/images/shared/home/technologies/go.svg";
import { TechnologyItem } from "../../interfaces/shared/static/technologies";

const technologiesList: TechnologyItem[] = [
  { label: "c", image: c },
  { label: "c++", image: cPlusPlus },
  { label: "c#", image: cSharp },
  { label: "obj-c", image: objC },
  { label: "java", image: java },
  { label: "swift", image: swift, smallImage: true },
  { label: "kotlin", image: kotlin, smallImage: true },
  { label: "native", image: nativeImage, smallImage: true },
  { label: "flutter", image: flutter, smallImage: true },
  { label: "react", image: react, smallImage: false },
  { label: "ionic", image: ionic, smallImage: true },
  { label: "xamarin", image: xamarin, smallImage: false },
  { label: "angular", image: angular, smallImage: true },
  { label: "html", image: html, smallImage: true },
  { label: "js", image: js, smallImage: true },
  { label: "php", image: php, smallImage: true },
  { label: "python", image: python, smallImage: true },
  { label: "ruby", image: ruby, smallImage: true },
  { label: "solidify", image: solidify, smallImage: true },
  { label: "rust", image: rust, smallImage: true },
  { label: "view", image: view, smallImage: true },
  { label: "go", image: go, smallImage: true },
];

export default technologiesList;
