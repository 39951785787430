import { useState, useEffect } from "react";
import { getCaseStudyDetails } from "./actions";
import {
  CaseStudyDetailsHookResponse,
  Project,
} from "../../../interfaces/api/caseStudy";

const useCaseStudyDetails = (slug: string): CaseStudyDetailsHookResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [caseStudy, setCaseStudy] = useState<Project>({} as Project);
  const [similarCaseStudies, setSimilarCaseStudies] = useState<Project[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);
        const res = await getCaseStudyDetails(slug);
        if (res) {
          setCaseStudy(res.details);
          setSimilarCaseStudies(res.similar);
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [slug]);

  return { isLoading, caseStudy, similarCaseStudies, isError };
};

export default useCaseStudyDetails;
