import React from "react";
import styles from "./webSolutionsDetails.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import { useTranslation } from "react-i18next";
import { letsTalk } from "../../../../../shared/services/contactService";
const WebSolutionsDetails = () => {
  const { t } = useTranslation();
  const laptop = useResponsiveImage(ImageKeys.webSolutionsLaptop);
  const tablet = useResponsiveImage(ImageKeys.webSolutionsTablet);
  return (
    <section id={"details"} className={styles["details-container"]}>
      <div className={`${styles["left-section"]} relative`}>
        <LazyLoadImage
          src={laptop}
          alt={"laptop"}
          width={"100%"}
          height={"auto"}
        />
        <p>{t("webSolutions.laptop")}</p>
      </div>
      <div className={`${styles["right-section"]} relative`}>
        <p>{t("webSolutions.tablet")}</p>
        <LazyLoadImage
          src={tablet}
          alt={"tablet"}
          width={"100%"}
          height={"auto"}
        />
      </div>
      <div className={"d-flex justify-content-center"}>
        <p className={`letsTalk ${styles["custom-letsTalk"]}`}>
          {t("webSolutions.notOnline")}{" "}
          <a href={"#contact"} onClick={(event) => letsTalk(event)}>
            {t("app.letsTalk")}
          </a>
        </p>
      </div>
    </section>
  );
};

export default WebSolutionsDetails;
