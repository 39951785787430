import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./phoneSection.module.scss";
import useResponsiveImage from "../../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../../assets/images";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";

const PhoneSection: React.FC = () => {
  const { t } = useTranslation();
  const phoneImage = useResponsiveImage(ImageKeys.mobileAppsPhone);
  const isDesktop = useIsDesktop();

  return (
    <section
      className={`${styles["phoneSection-container"]} relative`}
      id={"phone-section"}
    >
      {isDesktop && (
        <img
          src={phoneImage}
          alt={"phone details"}
          width={"auto"}
          height={"auto"}
        />
      )}
      <p>{t("mobileApps.phoneSection")}</p>
    </section>
  );
};

export default PhoneSection;
