import React from "react";
import styles from "./ourServices.module.scss";
import { useTranslation } from "react-i18next";
import { FaArrowDownLong } from "react-icons/fa6";
import sideMenu from "../../../../config/sideMenu";
import { NavLink } from "react-router-dom";
import { isBrowserDefined } from "../../../../shared/services/browserService";

const OurServices = () => {
  const { t } = useTranslation();
  const scrollToBottom = () => {
    if (!isBrowserDefined()) return;
    window.scrollTo({ top: window.innerHeight - 60, behavior: "smooth" });
  };
  return (
    <section className={styles["links-container"]} id={"links"}>
      <div className={styles["links-list"]}>
        <div
          className={`${styles["arrow-container"]} d-flex align-items-center justify-content-center`}
        >
          <button onClick={scrollToBottom} aria-label={"scroll to services"}>
            <FaArrowDownLong />
          </button>
        </div>
        <div className={styles["menu-list"]}>
          {sideMenu[0].children?.map((item) => (
            <NavLink className={"animated-link"} key={item.path} to={item.path}>
              <div className={"d-flex align-items-center"}>
                {t(item.label)}
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <circle
                      cx="15.5"
                      cy="15.5"
                      r="14"
                      stroke="white"
                      strokeWidth="3"
                    />
                    <path
                      d="M21.7071 16.2071C22.0976 15.8166 22.0976 15.1834 21.7071 14.7929L15.3431 8.42893C14.9526 8.03841 14.3195 8.03841 13.9289 8.42893C13.5384 8.81946 13.5384 9.45262 13.9289 9.84315L19.5858 15.5L13.9289 21.1569C13.5384 21.5474 13.5384 22.1805 13.9289 22.5711C14.3195 22.9616 14.9526 22.9616 15.3431 22.5711L21.7071 16.2071ZM2 16.5H21V14.5H2V16.5Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <div className={`${styles["text-center"]} d-flex justify-content-center`}>
        <h2 className={styles["ourServices-title"]}>{t("home.ourServices")}</h2>
      </div>
    </section>
  );
};

export default OurServices;
