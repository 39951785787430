import { SideMenuItem } from "../interfaces/config/sideMenu";
import { screens } from "./routes";

const sideMenu: SideMenuItem[] = [
  {
    label: "menu.services",
    path: "",
    children: [
      {
        label: "menu.mobileApps",
        path: screens.mobileApps,
      },
      {
        label: "menu.industrySpecificSoftware",
        path: screens.industrySpecificSoftware,
      },
      {
        label: "menu.webSolutions",
        path: screens.webSolutions,
      },
      {
        label: "menu.userExperienceDesign",
        path: screens.userExperienceDesign,
      },
      {
        label: "menu.branding",
        path: screens.branding,
      },
      {
        label: "menu.digitalMarketing",
        path: screens.digitalMarketing,
      },
      {
        label: "menu.animation",
        path: screens.animation,
      },
    ],
  },
  {
    label: "menu.insights",
    path: screens.insights,
  },
  {
    label: "menu.caseStudy",
    path: screens.caseStudy,
  },
  {
    label: "menu.about",
    path: screens.about,
  },
  {
    label: "menu.contact",
    path: screens.contactUs,
  },
];

export default sideMenu;
