import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./technologies.module.scss";
import technologiesList from "../../../../shared/static/technologies";

const Technologies: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section
      className={`${styles["technologies-container"]} relative`}
      id={"technologies"}
    >
      <h2 className={styles["technologies-title"]}>{t("home.technologies")}</h2>
      <div className={styles["technologiesImages-container"]}>
        {technologiesList.map((item, idx) => (
          <div
            key={idx}
            className={`${item.smallImage ? styles["small-image"] : ""} d-flex justify-content-center align-items-center`}
          >
            <img
              src={item.image}
              alt={item.label}
              width={"auto"}
              height={"auto"}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Technologies;
