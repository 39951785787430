import React from "react";
import styles from "./results.module.scss";
import { useTranslation } from "react-i18next";
import { CaseStudyResultProps } from "../../../../../interfaces/screens/caseStudyDetails";
import ResponsiveMockup from "../../../../../components/responsiveMockup";
import detailsMockupConfigs from "../../../../../shared/static/detailsMockupConfig";
import { getCaseStudyPlaceholder } from "../../../../../shared/static/caseStudy";

const Results: React.FC<CaseStudyResultProps> = ({
  results,
  images,
  type,
  isMobile,
}) => {
  const { t } = useTranslation();
  const config = type !== undefined ? detailsMockupConfigs[type] : {};

  return (
    <section id={"results"} className={`${styles["results-container"]} `}>
      {images.length > 0 && (
        <div className={styles["main-image-container"]}>
          <div
            className={`${isMobile && styles["main-mobile-list"]} d-flex justify-content-center`}
          >
            {images.map((image, idx) => (
              <div key={image.id} className={styles["main-image"]}>
                <ResponsiveMockup
                  key={idx}
                  imageUrl={image.url?.desktop || getCaseStudyPlaceholder(type)}
                  {...(config as any)}
                />
              </div>
            ))}
            <div className={styles["gray-background"]}></div>
          </div>
        </div>
      )}
      <div className={`${styles["text-container"]} caseStudy-padding`}>
        <h2>{t("caseStudyDetails.results")}</h2>
        <p className={"mb-0"}>{results}</p>
      </div>
    </section>
  );
};

export default Results;
