import React, { useEffect, useState } from "react";
import Skeleton from "../../../../../components/skeleton";
import useDeviceType from "../../../../../shared/hooks/deviceType";
import { DeviceTypes } from "../../../../../shared/utils/enums";

const CaseItemSkeleton = () => {
  const [height, setHeight] = useState(311);
  const deviceType = useDeviceType();

  useEffect(() => {
    if (deviceType === DeviceTypes.mobile) {
      setHeight(250);
    } else if (deviceType === DeviceTypes.tablet) {
      setHeight(300);
    }
    setHeight(311);
  }, [deviceType]);

  return (
    <Skeleton
      width={"96%"}
      height={`${height}px`}
      borderRadius={"15px"}
      marginBottom={"4%"}
    />
  );
};

export default CaseItemSkeleton;
