import React from "react";
import { InsightCarouselItemProps } from "../../interfaces/components/insightCarouselItem";
import { extractContent, truncateText } from "../../shared/utils/helper";
import styles from "./insightCarousel.module.scss";
import LazyLoadImage from "../../shared/components/lazyLoadImage";

const InsightCarouselItem: React.FC<InsightCarouselItemProps> = ({
  title,
  description,
  image,
  onClick,
  originalImage,
  placeholder,
}) => {
  return (
    <button
      aria-label={title}
      onClick={onClick}
      className={`${styles["insightCarouselItem-container"]}`}
    >
      <LazyLoadImage
        src={image}
        alt={title}
        originalImage={originalImage}
        placeholderSrc={placeholder}
      />
      <h2>{truncateText(title, 60)}</h2>
      <p>{extractContent(description, 10, "...", 63)}</p>
    </button>
  );
};

export default InsightCarouselItem;
