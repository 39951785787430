import React from "react";
import Carousel from "../../../../../shared/components/carousel";
import styles from "./insightCarousel.module.scss";
import InsightCarouselItem from "../../../../../components/insightCarouselItem";
import { Blog } from "../../../../../interfaces/api/blogs";
import { InsightCarouselProps } from "../../../../../interfaces/components/insightCarousel";
import { extractContent } from "../../../../../shared/utils/helper";
import { screens } from "../../../../../config/routes";
import { useNavigate } from "react-router-dom";
import useIsDesktop from "../../../../../shared/hooks/isDesktop";

const InsightCarousel: React.FC<InsightCarouselProps> = ({ list }) => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const goToDetails = (slug: string) => {
    navigate("/" + screens.insightDetails + slug);
  };
  const renderItem = ({ item }: { item: Blog }) => {
    return (
      <InsightCarouselItem
        image={item.image.mobile}
        title={item.title}
        description={extractContent(item.content, 30, "...")}
        onClick={() => goToDetails(item.slug)}
        originalImage={item.image.original}
        placeholder={item.image.thumbnail}
      />
    );
  };

  return (
    <div className={styles["insightCarousel-container"]}>
      <Carousel
        list={list}
        renderItem={renderItem}
        loop
        className={`carousel-custom`}
        slidesToShow={isDesktop ? 2 : 1.5}
        showDots={false}
        arrows={isDesktop}
      />
    </div>
  );
};

export default InsightCarousel;
