import { services } from "../../../api";
import { CaseStudyCategory, Project } from "../../../interfaces/api/caseStudy";

export const getCaseStudyDetails = async (
  slug: string,
): Promise<{ details: Project; similar: Project[] } | null> => {
  try {
    const res = await services.caseStudy.getCaseStudyDetails(slug);
    if (res.result !== "success") {
      throw new Error("Blogs error");
    }
    return {
      details: res.content.project,
      similar: res.content.similarProjects?.data || [],
    };
  } catch (e) {
    return null;
  }
};
export const getCaseStudyList = async (
  categoryIds: number[],
  page: number,
  perPage: number,
): Promise<Project[] | null> => {
  try {
    const res = await services.caseStudy.getProjectsByCategory(
      categoryIds.join(","),
      page,
      perPage,
    );
    if (res.result !== "success") {
      throw new Error("Blogs error");
    }
    return res.content?.data || [];
  } catch (e) {
    return null;
  }
};
export const getCategories = async (): Promise<CaseStudyCategory[] | null> => {
  try {
    const res = await services.caseStudy.getCategories();
    if (res.result !== "success") {
      throw new Error("Blogs error");
    }
    return res.content?.data?.sort((a, b) => a.id - b.id) || [];
  } catch (e) {
    return null;
  }
};
