import { AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { toastService } from "../shared/utils/toast";
import { isBrowserDefined } from "../shared/services/browserService";

/**
 * Error handler function that displays error messages or copies the error to the clipboard (if in debug mode).
 * @param {any} err - The error object.
 * @returns {boolean} Returns true to indicate that the error has been handled.
 */
const handleError = (err: any): boolean => {
  if (!isBrowserDefined()) return true;
  try {
    toastService.showErrorToast(
      err.response?.data?.message ||
        (err?.data?.error_description &&
          err?.data?.error_description?.length > 0)
        ? err.data?.error_description[0]
        : "Something went wrong, please try again later!",
    );
    return true;
  } catch (e) {
    toastService.showErrorToast();
    return true;
  }
};

/**
 * Http service that provides methods for making HTTP requests.
 * @type {object}
 */
const http = {
  /**
   * Performs a GET request.
   * @param {string} url - The URL to send the GET request to.
   * @param {object} headers - Optional headers to be included in the request.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  get: (url: string, headers: object = {}): Promise<any> =>
    new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { headers })
        .then((res: AxiosResponse<any>) => {
          if (res.data.result !== "success") {
            handleError(res);
          }
          resolve(res.data);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    }),
  /**
   * Performs a POST request.
   * @param {string} url - The URL to send the POST request to.
   * @param {any} body - The request body.
   * @param {object} headers - Optional headers to be included in the request.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  post: (url: string, body: any, headers: object = {}): Promise<any> =>
    new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body, { headers })
        .then((res) => {
          if (res.data.result !== "success") {
            handleError(res);
          }
          resolve(res.data);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    }),
  /**
   * Performs a DELETE request.
   * @param {string} url - The URL to send the DELETE request to.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  delete: (url: string): Promise<any> =>
    new Promise((resolve, reject) => {
      axiosInstance
        .delete(url)
        .then((res) => {
          if (res.data.result !== "success") {
            handleError(res);
          }
          resolve(res.data);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    }),
};

export default http;
