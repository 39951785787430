import React, { useEffect, useRef } from "react";
import { MainSectionProps } from "../../../interfaces/shared/components/mainSection";
import { ImageKeys } from "../../../assets/images";
import styles from "./mainSection.module.scss";
import Button from "../../../components/button";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaArrowDownLong } from "react-icons/fa6";
import { isBrowserDefined } from "../../services/browserService";
import { scrollToContact } from "../../services/contactService";
import welcomeVideo from "../../../assets/video/home.webm";
import { getThumbnail } from "../../utils/imageHelper";
import LazyVideo from "../../../components/lazyVideo";

const MainSection: React.FC<MainSectionProps> = ({
  label,
  image,
  description,
  showBrief,
  containerClass = "",
  gradientBackground,
  bigTitle,
  showConsultationButton,
  transparentBackground = false,
  smallLabelStyle = false,
  labelClass = "",
  descriptionClass = "",
  imageClass = "",
  consultationButtonClass = "",
  bottomContainerClass,
  customClassName = "",
}) => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef) {
      videoRef.current?.play();
    }
  }, []);
  const scrollToBottom = () => {
    if (!isBrowserDefined()) return;
    window.scrollTo({ top: window.innerHeight - 60, behavior: "smooth" });
  };
  return (
    <section
      id={"main-section"}
      className={`${styles["mainSection-container"]} ${transparentBackground ? styles["transparent-background"] : ""} ${gradientBackground ? styles["gradient-background"] : ""} ${containerClass}`}
    >
      {!gradientBackground && !transparentBackground && (
        <LazyVideo
          src={welcomeVideo}
          controls={false}
          autoPlay
          muted
          loop
          poster={getThumbnail(ImageKeys.mainSectionBackground)}
          className={styles["background-image"]}
          width={"100%"}
          height={"auto"}
          aria-label={"background"}
        />
      )}
      {!image && (
        <div
          className={`${smallLabelStyle ? styles["bottom-description"] : ""} ${styles["center-container"]} d-flex flex-column justify-content-center align-items-center ${customClassName} `}
        >
          <h1
            className={`${bigTitle ? styles["big-title"] : "mb-0"} ${labelClass}`}
          >
            {t(label)}
          </h1>
          {description && <p className={descriptionClass}>{t(description)}</p>}
          {showConsultationButton && (
            <Button
              label={t("app.freeConsultation")}
              onClick={() => scrollToContact()}
            />
          )}
        </div>
      )}
      {image && (
        <div
          className={`${styles["image-container"]} ${smallLabelStyle ? styles["small-label"] : ""}`}
        >
          <h1 className={labelClass}>{t(label)}</h1>
          {description && <p className={descriptionClass}>{t(description)}</p>}
          <div
            className={
              "overflow-hidden d-flex align-items-center justify-content-center"
            }
          >
            <LazyLoadImage
              className={imageClass}
              src={image}
              alt={t(label)}
              width={"auto"}
              height={"auto"}
              visibleByDefault
            />
            <div
              className={`${styles["bottom-container"]} ${bottomContainerClass}`}
            >
              {showConsultationButton && (
                <Button
                  className={`${styles["freeConsultation"]} ${consultationButtonClass}`}
                  label={t("app.freeConsultation")}
                  onClick={() => scrollToContact()}
                />
              )}
              <button
                className={styles["arrow-btn"]}
                onClick={scrollToBottom}
                aria-label={"scroll to services"}
              >
                <FaArrowDownLong />
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MainSection;
