import React from "react";
import generateMeta from "../../../../shared/services/metadataService";
import metaData from "../../../../config/metaData";
import MainSection from "../../../../shared/components/mainSection";
import welcomeImage from "../../../../assets/images/shared/webSolutions/welcome.webp";
import styles from "./webSolutions.module.scss";
import WebSolutionsDetails from "./webSolutionsDetails";
import OurTechnicalExpertise from "./ourTechnicalExpertise";
import useResponsiveImage from "../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../assets/images";
import BottomSection from "../../../../shared/components/bottomSection";
import CaseStudyCarousel from "../../../../shared/components/caseStudyCarousel";

const WebSolutions = () => {
  const robotBackground = useResponsiveImage(
    ImageKeys.transparentRobotBackground,
  );

  return (
    <div className={styles["webSolutions-container"]}>
      {generateMeta({
        title: metaData.webSolutions.title,
        description: metaData.webSolutions.description,
      })}
      <MainSection
        gradientBackground
        label={"webSolutions.title"}
        image={welcomeImage}
        description={"webSolutions.description"}
        showConsultationButton
        labelClass={styles["main-section-label"]}
        descriptionClass={styles["main-section-description"]}
        imageClass={styles["main-section-image"]}
      />
      <WebSolutionsDetails />
      <OurTechnicalExpertise />
      <div className={"case-bottom-container"}>
        <CaseStudyCarousel />
      </div>
      <BottomSection />
    </div>
  );
};

export default WebSolutions;
