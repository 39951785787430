import mockup from "../../assets/images/mockups/mobile/full.svg";
import desktop from "../../assets/images/mockups/mac/full.svg";
import chrome from "../../assets/images/mockups/chrome/full.svg";
import branding from "../../assets/images/mockups/branding/full.svg";
import video from "../../assets/images/mockups/branding/full.svg";
import play from "../../assets/images/mockups/video/play.svg";
import { MockupConfigs } from "../../interfaces/components/caseStudyItem";

const detailsMockupConfigs: MockupConfigs = {
  mobile: {
    mockupUrl: mockup,
    x: 0.06,
    y: 0.04,
    width: 0.7,
    height: 0.9,
    borderRadius: 18,
    objectPosition: "center",
    overlayUrl: "",
    mockupWidth: "fit-content",
    imageHeight: 92,
    imageWidth: 55,
    type: "mobile",
  },
  laptop: {
    mockupUrl: desktop,
    x: 0.11,
    y: 0.145,
    width: 0.78,
    height: 0.64,
    borderRadius: 0,
    ImageObjectFit: "cover",
    objectPosition: "center",
    mockupObjectPosition: "center",
    blur: 20,
    blurY: 14,
    blurX: 10,
    blurWidth: 79,
    blurHeight: 66,
  },
  web: {
    mockupUrl: chrome,
    x: 0.06,
    y: 0.12,
    width: 0.88,
    height: 0.634,
    borderRadius: { bl: 8, br: 8 },
    ImageObjectFit: "cover",
    objectPosition: "center",
    mockupObjectPosition: "center",
    showImageOverMockup: true,
    imageHeight: 68,
    blur: 20,
    blurY: 5.5,
    blurX: 6,
    blurWidth: 88,
    blurHeight: 75,
  },
  branding: {
    mockupUrl: branding,
    x: 0.14,
    y: 0.13,
    width: 0.72,
    height: 0.574,
    borderRadius: 0,
    ImageObjectFit: "cover",
    objectPosition: "center",
    mockupObjectPosition: "center",
    showImageOverMockup: true,
    blur: 20,
  },
  animation: {
    mockupUrl: video,
    x: 0.14,
    y: 0.13,
    width: 0.72,
    height: 0.574,
    borderRadius: 0,
    ImageObjectFit: "cover",
    objectPosition: "center",
    mockupObjectPosition: "center",
    showImageOverMockup: true,
    blur: 20,
    overlayUrl: play,
  },
};

export default detailsMockupConfigs;
