import React, { useRef, useState } from "react";
import {
  BorderRadius,
  ResponsiveMockupProps,
} from "../../interfaces/components/responsiveMockup";
import { getCaseStudyPlaceholder } from "../../shared/static/caseStudy";
import styles from "./responsiveMockup.module.scss";

const ResponsiveMockup: React.FC<ResponsiveMockupProps> = ({
  className,
  imageUrl,
  mockupUrl,
  x,
  y,
  width,
  height,
  borderRadius,
  objectPosition,
  ImageObjectFit = "contain",
  mainWidth = "100%",
  mockupObjectPosition = "center",
  showImageOverMockup = false,
  overlayUrl = "",
  imageHeight = undefined,
  mockupWidth = "100%",
  imageWidth = undefined,
  blur = 0,
  type = "branding",
  blurWidth = 85,
  blurHeight = 66,
  blurX = 7.5,
  blurY = 9,
  isVideo = false,
  videoUrl = "",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const aspectRatio = (height / width) * 100; // Example for 16:9 aspect ratio: 56.25
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div
      className={className}
      style={{
        position: "relative",
        width: mainWidth,
        paddingBottom: `${aspectRatio}%`,
        height: 0,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: `${blurY}%`,
          right: 0,
          bottom: 0,
          width: `${blurWidth}%`,
          left: `${blurX}%`,
          height: `${blurHeight}%`,
          backdropFilter: `blur(${blur}px)`,
          zIndex: 1,
          pointerEvents: "none",
          borderRadius: formatBorderRadius(borderRadius),
        }}
      />
      <div
        style={{
          position: "absolute",
          top: `${y * 100}%`,
          left: `${x * 100}%`,
          width: `${imageWidth || width * 100}%`,
          height: `${imageHeight || height * 100}%`,
          overflow: "hidden",
          borderRadius: formatBorderRadius(borderRadius),
          zIndex: showImageOverMockup ? 3 : 2,
        }}
      >
        {isVideo ? (
          <video
            ref={videoRef}
            src={videoUrl}
            autoPlay={false}
            loop
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: ImageObjectFit,
              objectPosition,
              borderRadius: formatBorderRadius(borderRadius),
              border: "0px solid transparent",
              userSelect: "none",
              transform: `translate3d(0px, 0px, 0.1px)`,
            }}
          />
        ) : (
          <img
            src={imageUrl}
            alt="Content"
            style={{
              width: "100%",
              height: "100%",
              objectFit: ImageObjectFit,
              objectPosition,
              borderRadius: formatBorderRadius(borderRadius),
              border: "0px solid transparent",
              userSelect: "none",
              transform: `translate3d(0px, 0px, 0.1px)`,
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = getCaseStudyPlaceholder(type);
            }}
          />
        )}
      </div>
      {overlayUrl && (
        <div
          className={`${styles["overlay"]} ${isPlaying ? styles["playing"] : ""}`}
          style={{
            position: "absolute",
            top: `${y * 100}%`,
            left: `${x * 100}%`,
            width: `${imageWidth || width * 100}%`,
            height: `${height * 100}%`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 4,
            userSelect: "none",
          }}
          onClick={handleVideoClick}
        >
          <img
            src={overlayUrl}
            alt="Overlay"
            style={{ width: 30, height: 30 }}
            draggable="false"
          />
        </div>
      )}
      <img
        src={mockupUrl}
        alt="Mockup"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: mockupWidth,
          height: "100%",
          zIndex: 2,
          objectPosition: mockupObjectPosition,
          userSelect: "none",
        }}
        draggable="false"
      />
    </div>
  );
};

const formatBorderRadius = (borderRadius: BorderRadius | number): string => {
  if (typeof borderRadius === "number") {
    return `${borderRadius}px`;
  } else {
    return `${borderRadius.tl ?? 0}px ${borderRadius.tr ?? 0}px ${borderRadius.br ?? 0}px ${borderRadius.bl ?? 0}px`;
  }
};

export default ResponsiveMockup;
